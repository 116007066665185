<template>
  <Suspense>
    <div id="app" :class="appClass">
      <router-view></router-view>
    </div>
  </Suspense>
</template>

<script>
import { i18nextPromise } from "./config/lang.config";
import i18next from "i18next";
export default {
  name: "App",
  async setup() {
    await i18nextPromise;
    return {};
  },
  mounted() {
    // workaround for en-GB variations - check if i18next.language contains a 'en' string
    if (i18next.language.includes("en")) {
      i18next.changeLanguage("en");
    }

    setTimeout(() => {
      this.$store.dispatch("init");
    }, 100);
  },
  computed: {
    appClass() {
      const appClass = [];
      if (this.$route.name) {
        appClass.push(`route-${this.$route.name}`);
      }
      return appClass.join(" ");
    },
  },
};
</script>
