import app from "../main";
import Auth from "../services/auth.service";

export default {
  namespaced: true,
  state: {
    lang: { name: "English", code: "en", icon: "gb" },
    all: [
      { name: "English", code: "en", icon: "gb" },
      { name: "Danish", code: "da", icon: "dk" },
    ],
  },
  mutations: {
    lang(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    async changeLanguage({ commit, rootState, state }, lang) {
      const isUserLogged = rootState?.profile?.profile;
      await app.$i18next.changeLanguage(lang.code);

      commit(
        "lang",
        state.all.find((language) => language.code === app.$i18next.language)
      );
      if (isUserLogged) {
        await Auth.updateUserLanguage();
      }
    },
    initLanguage({ commit, state }) {
      commit(
        "lang",
        state.all.find((lang) => lang.code === app.$i18next.language)
      );
    },
  },
};
