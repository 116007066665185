<template>
  <svg
    width="254"
    height="80"
    viewBox="0 0 254 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.416371 9.89741C-0.813705 4.85904 3.00175 0 8.1881 0H246C250.418 0 254 3.58172 254 8V72C254 76.4183 250.418 80 246 80H8.18811C3.00176 80 -0.813707 75.141 0.416369 70.1026L7.30243 41.8974C7.60679 40.6508 7.60679 39.3492 7.30243 38.1026L0.416371 9.89741Z"
      fill="currentColor"
    />
  </svg>
</template>
