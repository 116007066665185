import http from "./http.service";

class Partner {
  setCurrentPartner(body) {
    return http.post("/api/user/current-partner", body).then((res) => res.data);
  }

  getCurrentPartner() {
    return http.get("/api/user/current-partner").then((res) => res.data);
  }

  createPartnerFromDistributor(body) {
    return http.post("/api/distributor/create-partner", body);
  }

  getCompaniesFromCurrentPartner() {
    return http.get("/api/partners/companies").then((res) => {
      res.data.data.forEach((el) => {
        el.company_status = el.active ? "active" : "non-activ";
      });
      return res.data;
    });
  }

  removeCompanyFromPartner(companyId) {
    return http
      .delete(`/api/partner/remove-company/${companyId}`)
      .then((res) => res.data);
  }

  takePaymentOwnership(companyId) {
    return http
      .post(`/api/partner/take-payment-ownership/${companyId}`)
      .then((res) => res.data);
  }

  returnPaymentOwnership(companyId) {
    return http
      .post(`/api/partner/return-payment-ownership/${companyId}`)
      .then((res) => res.data);
  }

  toggleCompanyStatus(companyId) {
    return http
      .post(`/api/partner/update-company-status/${companyId}`)
      .then((res) => res.data);
  }

  updateCompanyOwner({ companyId, email }) {
    return http
      .post(`/api/partner/update-company/${companyId}`, { email })
      .then((res) => res.data);
  }
}

export default new Partner();
