import Company from "@/services/company.service";

const INITIAL_STATE = {
  currentCompany: null,
  users: null,
  currentUser: null,
  companyFromPartner: null,
  userCompanies: null,
  dimensions: null,
  bankAccounts: null,
  permissions: null,
  editPermissions: null,
  editCompany: null,
  loggedUser: null,
};
export default {
  namespaced: true,
  state: {
    ...INITIAL_STATE,
  },
  mutations: {
    currentCompany(state, payload) {
      state.currentCompany = payload;
      return state.currentCompany;
    },
    currentUser(state, payload) {
      state.currentUser = payload;
    },
    loggedUser(state, payload) {
      state.loggedUser = payload;
    },
    permissions(state, payload) {
      state.permissions = payload;
    },
    editPermissions(state, payload) {
      state.editPermissions = payload;
    },
    users(state, payload) {
      state.users = payload;
    },
    editUser(state, payload) {
      state.editUser = payload;
    },
    editCompany(state, payload) {
      state.editCompany = payload;
    },
    companyFromPartner(state, payload) {
      state.companyFromPartner = payload;
    },
    userCompanies(state, payload) {
      state.userCompanies = payload;
    },
    setDimensions(state, payload) {
      state.dimensions = payload;
    },
    setBankAccounts(state, payload) {
      state.bankAccounts = payload;
    },
  },
  actions: {
    loggedUser(state, payload) {
      state.loggedUser = payload;
    },
    resetState({ state }) {
      Object.assign(state, INITIAL_STATE);
    },
    getCurrent({ commit }) {
      return Company.getCurrentCompany().then((res) => {
        commit("currentCompany", res.data);
        commit("permissions", res.data.permissions);
        commit("editPermissions", res.data.permissions);
        return res;
      });
    },
    getCurrentWithId({ commit }, body) {
      return Company.getCurrentCompanyWithId(body).then((res) => {
        commit("currentCompany", res.data);
        commit("permissions", res.data.permissions);
        commit("editPermissions", res.data.permissions);
      });
    },
    getCurrentWithIdForEditPermissions(_, body) {
      return Company.getCurrentCompanyWithId(body);
    },
    setCurrent(_, body) {
      return Company.setCurrentCompany(body);
    },
    setCurrentUser({ commit }, user) {
      commit("currentUser", user);
    },
    setPermission(state, payload) {
      state.isPermission = payload;
    },
    updateCurrentCompany({ dispatch }, body) {
      return Company.updateCurentCompany(body).then((res) => {
        dispatch("getCurrent");
        return res;
      });
    },
    getUsersForCompany({ commit }) {
      return Company.getUsersForCompany().then((res) => {
        commit("users", res.data);
        return res.data;
      });
    },
    getUserCompanies({ commit }) {
      return Company.getUserCompanies().then((res) => {
        commit("userCompanies", res.data);
      });
    },
    createCompany(_, body) {
      return Company.createCompany(body);
    },
    createCompanyFromPartner(_, body) {
      return Company.createCompanyFromPartner(body);
    },
    removeUserForCompany(_, id) {
      return Company.removeUserForCompany(id);
    },
    addUserForCompany({ state }, email) {
      if (typeof email !== "string") {
        if (email.length > 1) {
          for (let i = 0; i < email.length; i++) {
            Company.addUserForCompany(email[i]);
          }
          return;
        } else {
          return Company.addUserForCompany(email[0]);
        }
      }
      return Company.addUserForCompany({
        email: email,
        permissions: JSON.stringify(state.editPermissions || {}),
      });
    },
    addOrEditUserPermissionsToCompany(_, user) {
      console.log(user);
      return Company.addUserForCompany({
        ...user,
        permissions: JSON.stringify(user.permissions),
      });
    },
    editUserForCompany(_, user) {
      if (user.permissions.users.modify_permissions) {
        user.permissions.users.crud = true;
        user.permissions.users.view = true;
      }

      return Company.editUserForCompany({
        ...user,
        permissions: JSON.stringify(user.permissions),
      });
    },
    getCompanyBankAccounts({ commit }) {
      return Company.getCompanyBankAccounts().then((res) => {
        commit("setBankAccounts", res.data);
        return res.data;
      });
    },
    updateCompanyBankAccount(_, body) {
      return Company.updateCompanyBankAccount(body);
    },
  },
};
