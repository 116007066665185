<template>
	<div class="pt-20 min-h-fit flex items-center flex-col">
		<p class="text-lg font-bold">
			{{ $t("setup-wizard")
			}}<!-- Setup Wizard -->
		</p>
		<div class="flex justify-evenly w-full mt-10">
			<div v-for="(step, index) in wizardSteps" :key="step" class="w-1/4">
				<!-- DISABLE ARROW BUTTONS  -->
				<!-- @click.prevent="setActive(index)"  -->
				<WizardStep
					:text="$t(step)"
					:step="index + 1"
					:active="index == activeStep"
					:arrow="index !== wizardSteps.length - 1"
				/>
			</div>
		</div>
		<div v-if="activeStep == 0" class="flex flex-col items-center">
			<BasicButton
				@click.prevent="connectEconomic"
				color="primary"
				class="mt-20"
				><span class="font-bold uppercase"
					>{{ $t("connect-to-economic")
					}}<!-- CONNECT TO E-CONOMIC --></span
				>
			</BasicButton>
			<p class="my-4 text-sm font-semibold uppercase">
				{{ $t("or")
				}}<!-- OR -->
			</p>
			<BasicButton
				href="#"
				@click.prevent="skipStep()"
				class="underline underline-offset-2"
				><span class="text-base font-normal"
					>{{ $t("skip-and-continue")
					}}<!-- Skip and continue if you don't use E-conomic --></span
				></BasicButton
			>
		</div>
		<div v-if="activeStep == 1" class="flex flex-col items-center">
			<div class="pt-8 pb-4 w-64">
				<TextInput
					type="date"
					:label="$t('import-data') /* Import from */"
					@input="import_data_from = $event"
				/>
				<BasicMultiselect
					class="w-72"
					label="name"
					trackBy="name"
					field="id"
					valueProp="id"
					:label-text="$t('banks') /* Banks */"
					:placeholder="$t('select-bank') /* Select your bank */"
					labelClasses="font-medium"
					@input="bank_id = $event"
					:options="bank_names"
					:errors="errors"
					autocomplete="off"
				/>
			</div>
			<BasicButton color="primary" class="mt-20" @click.prevent="connectBank"
				><span class="font-bold uppercase"
					>{{ $t("connect-bank-account")
					}}<!-- CONNECT YOUR BANK ACCOUNT --></span
				>
			</BasicButton>
			<p v-if="activeStep !== 1" class="my-4 text-sm font-semibold">
				{{ $t("or")
				}}<!-- OR -->
			</p>
			<BasicButton
				v-if="activeStep !== 1"
				href="#"
				@click.prevent="skipStep()"
				class="underline underline-offset-2"
				><span class="text-base font-normal"
					>{{ $t("skip-step")
					}}<!-- Skip this step --></span
				></BasicButton
			>
		</div>
		<div v-if="activeStep == 2" class="w-fit">
			<div class="flex mt-14 w-full flex-col">
				<ul>
					<li
						v-for="(email, index) in emails"
						:key="email.id"
						class="flex w-full mb-4"
					>
						<BasicInput
							type="text"
							:placeholder="$t('email') /* Email */"
							:field="email.id"
							:value="email.value"
							:name="email.id"
							:id="email.id"
							@input="changeEmail({ index, event: $event })"
							class="pr-10 email-block"
							:errors="errors"
						/>
						<div
							v-if="
								emails.length === 1 ||
								(!errors.errors[email.id]?.length &&
									emails.length - 1 === index)
							"
							@click.prevent="addRowForEmail"
							class="bg-white relative w-10 h-10 rounded border border-lightText ml-2 cursor-pointer flex items-center justify-center"
							:class="{
								'opacity-50 pointer-events-none':
									errors.errors[email.id]?.length ||
									(index !== 0 && !emails[index]),
							}"
						>
							<span class="absolute plus">+</span>
						</div>
						<div
							v-else
							@click.prevent="removeEmail(index)"
							class="bg-white relative minus-block h-10 rounded border border-lightText ml-2 px-4 text-md cursor-pointer flex items-center justify-center"
						>
							<span class="absolute plus minus">-</span>
						</div>
					</li>
				</ul>
			</div>
			<BasicButton
				color="primary"
				class="mt-4"
				:disabled="Object.keys(errors.errors).length ? true : false"
				@click.prevent="sendEmailsAndGo"
				:fullWidth="true"
				><span class="font-bold uppercase text-base px-8 tracking-wide"
					>{{ $t("finish-setup")
					}}<!-- FINISH SETUP --></span
				>
			</BasicButton>
		</div>
	</div>
</template>

<script>
import WizardStep from "../../components/WizardStep";
import BasicButton from "../../components/basic/BasicButton";
import BasicInput from "../../components/basic/BasicInput";
import BasicMultiselect from "../../components/basic/BasicMultiselect";
import Errors from "@/utils/errors";
import { nordigen, RFC2822EMAILREGEX } from "@/utils/variables";
import { economic } from "@/utils/variables";
import Notifications from "@/services/notifications.service";
import TextInput from "@/components/basic/BasicInput.vue";

export default {
	name: "wizard-setup",
	i18nOptions: { namespaces: "wizard" },
	components: {
		TextInput,
		WizardStep,
		BasicButton,
		BasicInput,
		BasicMultiselect,
	},
	data() {
		return {
			bank_id: "",
			banks: [],
			bank_names: [],
			import_data_from: "",
			loading: false,
			wizardSteps: [
				"connect-erp" /* "Connect your ERP system" */,
				"connect-bank" /* "Connect your bank" */,
				"invite-users",
			],
			activeStep: Number(this.$route.query.id),
			appUrl: process.env.VUE_APP_APP,
			client_redirect_uri: nordigen.client_redirect_uri_wizard,
			ref: this.$route.query.ref,
			error: this.$route.query.error,
			emails: [
				{
					value: "",
					id: String(Math.random(1000)),
				},
			],
			errors: new Errors(),
			economicUrl: economic.baseUrl,
		};
	},
	async mounted() {
		const { token } = this.$route.query;
		if (token) {
			this.$store
				.dispatch("profile/connectEconomic", { token: token })
				.then(() => {
					Notifications.notify(
						"success",
						this.$t(
							"connection-success"
						) /* "Connected to E-Conomic successfully" */
					);
					this.activeStep = 1;
				});
		}
		console.log("wizard error", this.error);
		if (this.ref && !this.error) {
			console.log("next step after bank connect");
			this.loading = true;
			this.$store
				.dispatch("profile/connectBankCompleted", {
					ref: this.ref,
				})
				.then(() => {
					this.loading = false;
					this.activeStep = 2;
				});
		}

		this.banks = await this.$store.dispatch("profile/getNordigenBanks");

		this.banks.map((bank) => {
			bank?.countries?.map((country) => {
				const bank_name = {
					id: bank.id,
					name: `${country}: ${bank.name} (${bank.bic})`,
				};
				this.bank_names.push(bank_name);
			});
		});
	},
	methods: {
		addRowForEmail() {
			this.emails.push({
				value: "",
				id: String(Math.random(1000)),
			});
		},
		validateEmail({ index }) {
			const email = this.emails[index];
			if (index === 0 && email.value === "") {
				this.errors.clearErrorPropery(email.id);
				console.log(this.errors);
				return true;
			}
			if (!RFC2822EMAILREGEX.test(email.value)) {
				this.errors.set({
					...this.errors.errors,
					[email.id]: [this.$t("incorect-email") /* "Incorrect email" */],
				});
				console.log(this.errors);

				return false;
			} else {
				this.errors.clearErrorPropery(email.id);
				console.log(this.errors);

				return true;
			}
		},
		connectEconomic() {
			const apppUrl =
				process.env.VUE_APP_APP === "/"
					? "https://orprio.atomdev.dev"
					: process.env.VUE_APP_APP;
			window.location.href = `${this.economicUrl}&redirectUrl=${
				apppUrl + this.$route.fullPath
			}`;
		},
		changeEmail({ index, event }) {
			if (typeof event === "string") return;
			this.emails[index] = { ...this.emails[index], value: event.target.value };
			this.validateEmail({ index });
		},
		setActive(step) {
			if (this.activeStep >= step) return;
			if (step === 2) return;
			this.activeStep = step;
		},
		skipStep() {
			this.activeStep = this.activeStep + 1;
		},
		async sendEmailsAndGo() {
			this.loading = true;

			if (this.emails.length > 0) {
				this.emails.map((email) => {
					if (email.value === "") return;
					this.$store.dispatch("company/addUserForCompany", email.value);
				});
			}

			localStorage.setItem("loggedIn", true);
			this.$router.push("/choose");
		},
		async connectBank() {
			if (!this.bank_id) {
				Notifications.notify(
					"warning",
					this.$t("bank-error")
					/* "Bank account is not selected" */
				);
				return;
			}

			const response = await this.$store.dispatch("profile/connectBank", {
				bank_id: this.bank_id,
				client_redirect_uri: this.client_redirect_uri,
				import_data_from: this.import_data_from,
			});

			if (response.link) {
				window.open(response.link, "_self");
			} else {
				Notifications.notify(
					"warning",
					this.$t("bank-error")
					/* "Bank account is not selected" */
				);
			}
		},
		sendEmail() {
			this.loading = true;
			this.$store.dispatch("profile/sendEmail", this.email).then(() => {
				this.loading = false;
			});
		},
		removeEmail(index) {
			const email = this.emails[index];
			this.errors.clearErrorPropery(email.id);
			this.emails.splice(index, 1);
		},
	},
};
</script>
<style scoped>
.plus {
	font-size: 42px;
	font-weight: 200;

	top: -14px;
}
.minus {
	top: 4px;
	font-size: 68px;
	font-weight: 200;
}
.minus-block {
	min-width: 40px;
}
</style>
