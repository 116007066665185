import { memoizeDebounce } from "../utils/helper";

import { useToast } from "vue-toastification";

const toast = useToast();

class Notifications {
  notify = memoizeDebounce(
    (type, content, duration = null, dismissible = true) => {
      const params = {
        content,
        dismissible,
      };
      if (duration) {
        params.duration = duration;
      }
      if (type === "success") {
        return toast.success(content, params);
      }
      if (type === "warning") {
        return toast.warning(content, params);
      }
      if (type === "danger") {
        return toast.danger(content, params);
      }
      return toast(content, params);
    },
    50
  );

  success(content, title) {
    return toast.success({ content, title });
  }

  info(content, title) {
    return toast.info(content, title, 3000, false);
  }

  error(content, title) {
    return toast.error({ content, title });
  }
}

export default new Notifications();
