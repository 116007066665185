import http from "./http.service";
import {
  downloadDataFormatFileFromResponse,
  handleAllUrlFilters,
} from "@/utils/helper";

class Export {
  getDataForExportPage(filters) {
    return http.get(handleAllUrlFilters("/api/export", filters)).then((res) => {
      

      if (!filters || !filters["status_exported"]) {
        res.data.data = res.data.data.filter((el) => el.status !== "exported");
      }
      return res;
    });
  }
  exportToEconomics(invoiceMatch) {
    return http.post(`/api/export/${invoiceMatch.id}`);
  }
  exportToCsv() {
    return http.post("/api/export/csv", {}).then((res) => {
      downloadDataFormatFileFromResponse(res, "bank-transactions.csv");
    });
  }
}

export default new Export();
