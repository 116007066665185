import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { format } from "date-fns";
import Logo from "@/assets/pdf-logo.png";

export async function downloadPdf(data) {
  const doc = new jsPDF();
  const todayDate = new Date(Date.now());
  const width = doc.internal.pageSize.getWidth();
  const {
    filters: { from_date, to_date },
    bankStatements,
    currentBankAccount,
    currentCompany,
    quickLinks,
    meta,
    headers,
    titles: {
      bank_acc,
      total,
      company,
      open_transactions,
      bank_statement,
      to,
      from,
      value,
      filters,
      status,
    },
  } = data;

  // Logo
  doc.addImage(Logo, "PNG", 9, 9, 24, 7);

  // Timestamp
  doc
    .setTextColor("rgb(83,83,83)")
    .setFontSize(10)
    .text(format(todayDate, "dd.MM.yyyy"), width - 9, 9, {
      fontSize: 5,
      align: "right",
    });

  // Tile
  doc
    .setTextColor("black")
    .setFontSize(10)
    .text(`OPRIO - ${bank_statement}`, 9, 65, {
      align: "left",
    });

  // Current Company tables
  autoTable(doc, {
    startY: 70,
    avoidPageSplit: true,
    tableWidth: 63,
    margin: { left: 9,right: 10  },
    theme: "grid",
    headStyles: {
      fontSize: 10,
      fillColor: "#B6C8C9",
      textColor: "black",
      cellPadding: 2.7,
      lineColor: "white",
    },
    styles: {
      cellPadding: 2.6,
      fontSize: 9,
      textColor: "black",
    },
    head: [[company]],
    body: [[currentCompany.name]],
  });

  // Current BankAcccount tables
  autoTable(doc, {
    startY: 95,
    avoidPageSplit: true,
    tableWidth: 63,
    margin: { left: 9, top: 5,right: 10 },
    theme: "grid",
    headStyles: {
      fontSize: 9,
      fillColor: "#B6C8C9",
      textColor: "black",
      cellPadding: 2.7,
      lineColor: "white",
    },
    head: [[bank_acc]],
    styles: {
      cellPadding: 2.6,
      fontSize: 9,
      textColor: "black",
      overflow: "hidden",
    },
    body: [[currentBankAccount.bank_account_name]],
  });

  // Filters table
  autoTable(doc, {
    avoidPageSplit: true,
    startY: 70,
    tableWidth: 55,
    margin: { left: 80 },
    theme: "grid",
    headStyles: {
      fillColor: "#B6C8C9",
      textColor: "black",
      cellPadding: 3.59,
      fontSize: 8,
    },

    styles: {
      fontSize: 8,
      textColor: "black",
      overflow: "hidden",
      cellPadding: 3.78,
    },
    head: [[filters, value]],
    body: [
      [from, from_date ? format(new Date(from_date), "dd.MM.yyyy") : "-"],
      [to, to_date ? format(new Date(to_date), "dd.MM.yyyy") : "-"],
      [open_transactions, quickLinks ? quickLinks : "-"],
    ],
  });

  // Main table
  autoTable(doc, {
    head: [headers],
    margin: { top: 1, left: 9, right: 9 },
    theme: "grid",
    headStyles: {
      cellPadding: 3.59,
      fontSize: 8,
      fillColor: "#B6C8C9",
      textColor: "black",
    },
    styles: {
      fontSize: 8,
      textColor: "black",
      cellPadding: 3.58,
    },
    body: [
      ...bankStatements.map((row) => [
        row.date,
        row.text,
        String(row.amount),
        row.balance,
        status[row.status],
        row?.responsible?.name || "",
      ]),
      [
        "",
        total,
        "",
        meta && meta.total_amount ? meta.total_amount : 0,
        "",
        "",
      ],
      // ...
    ],
    didParseCell: (hookData) => {
      if (
        hookData.column.dataKey === 4 ||
        hookData.column.dataKey === 2 ||
        hookData.column.dataKey === 5
      ) {
        hookData.cell.styles.halign = "right";
      }
      if (hookData.column.dataKey === 4) {
        hookData.cell.styles.halign = "center";
      }
      if (hookData.row.index === data.bankStatements.length) {
        hookData.cell.styles.fontSize = 10;
      }
    },
  });

  return await doc.save(`BankStatements-${currentCompany.name}.pdf`);
}
