import {createApp} from "vue";
import App from "./Suspencer.vue";
import router from "./config/router.config";
import "./index.css";
import store from "./store/store";
import lang from "./config/lang.config";
import * as uiv from "uiv";
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueEasyLightbox from "vue-easy-lightbox";

const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = (event) => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};

const app = lang(createApp(App))
    .use(store)
    .use(uiv)
    .use(Toast, {
        position: POSITION.BOTTOM_CENTER,
        timeout: 6000,
    })
    .use(router)
    .use(VueEasyLightbox)
    .directive("click-outside", clickOutside)
    .mount("#app");

export default app;
