
import http from "./http.service";

class Inbox {
    
    getAllOpenDocuments() {
        return http.get('/api/inbox/open-documents');
    }

    getAllOpenDocumentTransactions() {
        return http.get('/api/inbox/open-transactions');
    }

    getWaitingPayments() {
        return http.get('/api/inbox/waiting-payment');
    }

    matchTransactionWithDocument(body) {
        return http.post('/api/inbox/invoice-match', body)
    }

    unmatchTrasactionWithDocument(matchId){
        return http.post(`/api/inbox/invoice-unmatch/${matchId}`)
    }

    markWithReady(matchId) {
        return http.post(`/api/inbox/invoice-match/export/${matchId}`)
    }

    switchToWaiting({doc_id, status}){
        return http.post(`/api/inbox/document-status/${doc_id}`, {status:status})
    }
}


export default new Inbox();