<template>
  <!-- <div
    :class="[colorClassBox, arrowClass]"
    class="relative rounded-lg flex items-center justify-center w-full h-20 font-bold px-10 aa"
  >
    <p class="text-md pr-4" :class="colorClassText">{{ step }}</p>
    <p class="text-base text-black">{{ text }}</p>
  </div> -->
  <div class="flex min-h-fit">
    <div class="relative w-full mr-24" :class="colorClassArrow">
      <div
        class="absolute left-0 top-0 transform rounded-lg h-full w-8"
        v-if="step === 1"
        :class="{ 'bg-white': !active, 'bg-primary': active }"
      ></div>
      <Arrow />
      <div
        class="absolute flex items-center mx-auto w-full justify-center top-2/4 left-2/4 transform -translate-x-1/2 -translate-y-1/2 font-bold"
      >
        <p class="text-md pr-4" :class="colorClassText">{{ step }}</p>
        <p class="text-base text-black">{{ text }}</p>
      </div>
      <div
        v-if="arrow"
        class="absolute right-0 top-0 transform translate-x-1/2"
        :class="colorClassArrow"
      >
        <ArrowHead />
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "../svg/ArrowSVG";
import ArrowHead from "../svg/ArrowHeadSVG";
export default {
  name: "wizard-step",
  components: {
    Arrow,
    ArrowHead,
  },
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: false,
      default: "Wizard Step",
    },
    step: {
      type: Number,
      required: false,
      default: 0,
    },
    arrow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    colorClassArrow() {
      return !this.active ? "text-white" : "text-primary";
    },
    colorClassText() {
      return !this.active ? "text-primary" : "text-white";
    },
    arrowClass() {
      return this.arrow ? " after:content-[''] after:absolute after:-z-50" : "";
    },
  },
};
</script>

<style></style>
