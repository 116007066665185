import http from "./http.service";

class Company {
  setCurrentCompany(body) {
    return http.post("/api/user/current-company", body).then((res) => res.data);
  }

  updateCurentCompany(body) {
    return http.post("/api/company/update", body).then((res) => res.data);
  }

  getCurrentCompany() {
    return http.get("/api/user/current-company").then((res) => res.data);
  }

  getCurrentCompanyWithId({ company_id, user_id }) {
    return http
      .get(`/api/company/${company_id}?user_id=${user_id}`)
      .then((res) => res.data);
  }

  getCompanyBankAccounts() {
    return http.get("/api/bank-accounts").then((res) => res.data);
  }

  createCompany(body) {
    return http.post("api/company", body);
  }

  createCompanyFromPartner(body) {
    return http.post("/api/partner/create-company", body);
  }

  getUsersForCompany() {
    return http.get("/api/company/users").then((res) => {
      res.data.data.forEach((el) => {
        if (el["email_verified_at"]) {
          el.status = "active";
        } else {
          el.status = "invite-pending";
        }
      });
      return res.data;
    });
  }

  getUserCompanies() {
    return http.get("/api/companies").then((res) => res.data);
  }

  addUserForCompany(body) {
    return http.post("/api/company/add-user", { ...body });
  }

  removeUserForCompany(userId) {
    return http.post("/api/company/remove-user", { user_id: userId });
  }

  async editUserForCompany(body) {
    body.name = body.first_name + " " + body.last_name;
    return await http.post("/api/company/update-user", body);
  }

  companyShow(companyId) {
    return http.get(`/api/company/${companyId}`).then((res) => res.data);
  }

  getCompanyErp() {
    return http.get("/api/company/erp").then((res) => res.data.data);
  }

  updateCompanyBankAccount(body) {
    return http
      .post(`/api/bank-accounts/${body.id}`, body)
      .then((res) => res.data);
  }
}

export default new Company();
