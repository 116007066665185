/* eslint-disable no-prototype-builtins */
import { createRouter, createWebHistory } from "vue-router";
import Landing from "@/views/general/TheLanding.vue";
import Signup from "@/views/general/SignUp.vue";
import Error404 from "@/views/general/Error404.vue";
import CompanyDetails from "@/views/general/CompanyDetails.vue";
import WizardSetup from "@/views/general/WizardSetup.vue";
import ChooseAccount from "@/views/general/ChooseAccount.vue";
import JoinDistributor from "@/views/general/JoinDistributor.vue";
import JoinPartner from "@/views/general/JoinPartner.vue";
import store from "@/store/store";
import PartnerDetails from "@/views/general/PartnerDetails.vue";
import Notifications from "@/services/notifications.service";
import { companyNav } from "@/utils/variables";
// import i18n from "./lang.config";

const routes = [
	{
		path: "/dashboard",
		name: "dashboard",
		redirect: "/d/partners",
		component: () => import("../views/distributors/DDashboard.vue"),
		children: [
			{
				path: "/d/partners",
				name: "d-partners",
				component: () => import("../views/distributors/DPartnersView.vue"),
				meta: { authorize: ["distributor"] },
			},
			{
				path: "/d/partners/create",
				name: "d-partners-create",
				component: () => import("../views/distributors/DCreatePartnerView.vue"),
				meta: { authorize: ["distributor"] },
			},
			{
				path: "/d/partners/edit/:partnerId",
				name: "d-partners-edit",
				component: () => import("../views/distributors/DCEditPartnerView.vue"),
				meta: { authorize: ["distributor"] },
			},
			{
				path: "/d/companies",
				name: "d-companies",
				component: () => import("../views/distributors/DCompaniesView.vue"),
				meta: { authorize: ["distributor"] },
			},
		],
		meta: { authorize: ["distributor"] },
	},
	{
		path: "/dashboard-partner",
		name: "dashboard-partner",
		redirect: "/p/companies",
		component: () => import("../views/partners/PDashboard.vue"),
		children: [
			{
				path: "/p/companies",
				name: "p-companies",
				component: () => import("../views/partners/PCompaniesView.vue"),
				meta: { authorize: ["partner"] },
			},
			{
				path: "/p/companies/create",
				name: "p-companies-create",
				component: () => import("../views/partners/PCreateCompanyView.vue"),
				meta: { authorize: ["partner"] },
			},
			{
				path: "/p/companies/edit/:companyId",
				name: "p-companies-edit",
				component: () => import("../views/partners/PEditCompanyView.vue"),
				meta: { authorize: ["partner"] },
			},
		],
		meta: { authorize: ["partner"] },
	},
	{
		path: "/c/dashboard",
		name: "dashboard-company",
		redirect: "/c/dashboard/table",
		component: () => import("../views/companies/CDashboard.vue"),
		children: [
			{
				path: "/c/bank-statement",
				name: "c-bank-statement",
				component: () => import("../views/companies/CBankStatementView.vue"),
				meta: { authorize: ["company"], code: "bank_statement" },
			},
			{
				path: "/c/myaccount",
				name: "c-my-account",
				component: () => import("../views/companies/CMyAccountView.vue"),
			},
			{
				path: "/c/document/add",
				name: "c-document-add",
				component: () => import("../views/companies/CAddNewDocument.vue"),
				meta: { authorize: ["company"], code: "new_document" },
			},
			{
				path: "/c/document/create",
				name: "c-document-create",
				component: () => import("../views/companies/CCreateNewDocument.vue"),
				meta: { authorize: ["company"] },
			},
			{
				path: "/c/users",
				name: "c-users",
				component: () => import("../views/companies/CUsersView.vue"),
				redirect: "/c/users/table",
				children: [
					{
						path: "/c/users/table",
						name: "c-users-table",
						component: () => import("../views/companies/CUsersTableView.vue"),
					},
					{
						path: "/c/users/invite",
						name: "c-users-invite",
						component: () => import("../views/companies/CUsersInviteView.vue"),
					},
					{
						path: "/c/users/edit/:userId",
						name: "c-users-edit",
						component: () => import("../views/companies/CUsersEditView.vue"),
					},
				],
				meta: { authorize: ["company"] },
			},
			{
				path: "/c/company/setup",
				name: "c-company-setup",
				redirect: "/c/company/setup/main",
				component: () => import("../views/companies/CCompanySetupView.vue"),
				meta: { authorize: ["company"], code: "company_setup" },
				children: [
					{
						path: "/c/company/setup/main",
						name: "c-company-main",
						component: () =>
							import("../views/companies/company-setup/CMainCompanyData.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/erp",
						name: "c-company-erp",
						component: () =>
							import("../views/companies/company-setup/CErp.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/bank",
						name: "c-company-bank",
						component: () =>
							import("../views/companies/company-setup/CBankSetup.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/labels",
						name: "c-labels",
						component: () =>
							import("../views/companies/company-setup/CLabels.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/labels/edit",
						name: "c-labels-edit",
						component: () =>
							import("../views/companies/company-setup/CLabelsEditView.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/labels/add",
						name: "c-labels-add",
						component: () =>
							import("../views/companies/company-setup/CLabelsAddView.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/dimensions",
						name: "c-dimensions",
						component: () =>
							import("../views/companies/company-setup/CDimensions.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/dimensions/add",
						name: "c-dimensions-add",
						component: () =>
							import("../views/companies/company-setup/CDimensionsAdd.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/dimensions/edit",
						name: "c-dimensions-edit",
						component: () =>
							import("../views/companies/company-setup/CDimensionEdit.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/rulesd",
						name: "c-rules-documents",
						component: () =>
							import("../views/companies/company-setup/CRulesDocuments.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/rulesd/edit/:docId",
						name: "c-rulesd-edit",
						component: () =>
							import("../views/companies/company-setup/CRulesDEditView.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/rulesd/add",
						name: "c-rulesd-add",
						component: () =>
							import("../views/companies/company-setup/CRulesDAddView.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/rulest",
						name: "c-rules-transactions",
						component: () =>
							import("../views/companies/company-setup/CRulesTransactions.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/rulest/edit/:docId",
						name: "c-rulest-edit",
						component: () =>
							import("../views/companies/company-setup/CRulesTEditView.vue"),
						meta: { authorize: ["company"] },
					},
					{
						path: "/c/company/setup/rulest/add",
						name: "c-rulest-add",
						component: () =>
							import("../views/companies/company-setup/CRulesTAddView.vue"),
						meta: { authorize: ["company"] },
					},
				],
			},
			{
				path: "/c/dashboard/table",
				name: "c-dashboard",
				component: () => import("../views/companies/CDashboardTableView.vue"),
				meta: { authorize: ["company"] },
			},
			{
				path: "/c/dashboard/accountant/table",
				name: "c-dashboard-a",
				component: () =>
					import("../views/companies/CDashboardAccountantTableView.vue"),
				meta: { authorize: ["company"] },
			},
			{
				path: "/c/export",
				name: "c-export",
				component: () => import("../views/companies/CExportView.vue"),
				meta: { authorize: ["company"], code: "export" },
			},
			{
				path: "/c/inbox",
				name: "c-inbox",
				component: () => import("../views/companies/CInboxView.vue"),
				meta: { authorize: ["company"], code: "inbox" },
			},
			{
				path: "/c/help",
				name: "c-help",
				component: () => import("../views/companies/CHelpView.vue"),
				meta: { authorize: ["company"] },
			},
		],
		meta: { authorize: ["company"] },
	},
	{
		path: "/",
		component: () => import("../views/general/Public.vue"),
		children: [
			{
				path: "/",
				name: "landing",
				component: Landing,
			},
			{
				path: "/login",
				name: "login-tab",
				component: () => import("../views/general/LogIn.vue"),
			},
			{
				path: "/signup",
				name: "signup-tab",
				component: Signup,
			},
			{
				path: "/wizard",
				name: "wizard",
				component: WizardSetup,
			},
			{
				path: "/confirm",
				name: "email-confirm",
				component: () => import("../views/general/ConfirmEmailView.vue"),
			},
			{
				path: "/email-confirmation",
				name: "email-confirmation",
				component: () => import("../views/general/EmailConfirmationView.vue"),
			},
			{
				path: "/company-details",
				name: "company-details",
				component: CompanyDetails,
			},
			{
				path: "/partner-details",
				name: "partner-details",
				component: PartnerDetails,
			},
			{
				path: "/choose",
				name: "choose",
				component: ChooseAccount,
				meta: { authorize: ["account"] },
			},
			{
				path: "/password/reset/:email/:token",
				name: "forgot",
				component: () => import("../views/general/ForgotPasswordView.vue"),
			},
			{
				path: "/join/d",
				name: "join-d",
				component: JoinDistributor,
			},
			{
				path: "/join/p",
				name: "join-p",
				component: JoinPartner,
				meta: { authorize: ["public-private-partner"] },
			},
			{
				path: "/:pathMatch(.*)*",
				name: "not-found",
				component: Error404,
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to) {
		const selector = to.hash || "body";
		return {
			selector: selector,
			offset: { x: 0, y: 80 },
		};
	},
});

router.beforeEach(async (to, from, next) => {
	const checkLog = localStorage.getItem("loggedIn");
	const roleCheck = localStorage.getItem("orprio_role");
	const { authorize } = to.meta;
	const lengthOfAuth = !authorize ? 0 : authorize.length;


	// fixes the issue for other you have to be logged in or not to access accept screen page
	// NOTE: DO NOT CHANGE COMPARISON OPERATOR TO ===
	if (authorize == "public-private-partner"){
		return next();
	}

	let rolePath;
	if (roleCheck === "partner") rolePath = "dashboard-partner";
	if (roleCheck === "company") rolePath = "dashboard-company";
	if (roleCheck === "distributor") rolePath = "dashboard";

	if (lengthOfAuth && !checkLog && !roleCheck) {
		return next({ name: "landing" });
	}

	if (lengthOfAuth === 0 && checkLog) {
		return rolePath ? next({ name: rolePath }) : next({ name: "choose" });
	}
	if (lengthOfAuth > 0) {
		if (!checkLog) {
			return next({ name: "landing" });
		}
		if (authorize[0] === "account") {
			store.dispatch("navbar/setOptions", []);
			store.commit("profile/setOwner", null);
			store.commit("profile/setAccountant", null);
			next();
			return;
		}
		if (lengthOfAuth === 1 && authorize[0] !== roleCheck) {
			return next({ name: rolePath });
		}
		if (lengthOfAuth > 1) {
			let flag = true;
			for (let i = 0; i < lengthOfAuth; i++) {
				if (roleCheck === authorize[i]) {
					flag = false;
				}
			}
			if (flag) return next({ name: rolePath });
		}
	}

	if (lengthOfAuth > 0) {
		if (authorize && authorize[0] === "company") {
			if (!store.state.company?.currentCompany) {
				await store.dispatch("company/getCurrent");
			}

			if (!store.state.profile?.profile) {
				await store.dispatch("profile/getProfile");
			}
			// OWNER
			if (
				store.state.profile.profile.id ===
				store.state.company.currentCompany.owner_id
			) {
				store.commit("profile/setOwner", true);
				store.commit("profile/setAccountant", false);
				store.dispatch("navbar/setOptions", companyNav);
				next();
				return;
			}
			store.commit("profile/setOwner", false);
			// OWNER

			// ACCOUNTANT
			if (
				store.state.profile.profile.id ===
				store.state.company.currentCompany.accountant?.id
			) {
				store.commit("profile/setAccountant", true);
				store.dispatch("navbar/setOptions", companyNav);
				next();
				return;
			}
			store.commit("profile/setAccountant", false);
			// ACCOUNTANT

			// NAVBAR
			store.dispatch(
				"navbar/setOptions",
				companyNav.filter(
					(item) =>
						(item.code &&
							store.state.company.currentCompany.permissions[item.code].view) ||
						!item.code
				)
			);
			// NAVBAR

			// PERMISSIONS
			if (
				to.meta.code &&
				store.state.company.currentCompany.permissions &&
				store.state.company.currentCompany.permissions[to.meta.code]
			) {
				store.commit("profile/setOwner", false);
				store.commit("profile/setAccountant", false);
				if (
					!store.state.company.currentCompany.permissions[to.meta.code].view
				) {
					Notifications.notify(
						"warning",
						localStorage.getItem("i18nextLng") === "da"
							? `Du er ikke autoriseret.`
							: `You are not authorized.`
					);
					next({ name: rolePath });
					return;
				} else {
					next();
				}
			} else {
				next();
				return;
			}
			// PERMISSIONS
		} else {
			console.log("nay");
			next();
			return;
		}
	} else {
		next();
		return;
	}
});

export default router;
