import throttle from "lodash.throttle";
import Profile from "../services/profile.service";
const ROLE_KEY = "orprio_role";
const INITIAL_STATE = {
  profile: null,
  role: null,
  name: null,
  distributors: null,
  partners: null,
  companies: null,
  owner: null,
  accountant: null,
  password: null,
  isConnected: null
};
export default {
  namespaced: true,
  state: {
    ...INITIAL_STATE,
  },
  mutations: {
    profile(state, payload) {
      state.profile = payload;
    },

    password(state, payload) {
      state.password = payload;
    },
    setOwner(state, payload) {
      state.owner = payload;
    },
    setAccountant(state, payload) {
      state.accountant = payload;
    },
    role(state, payload) {
      state.role = payload;
    },
    companies(state, payload) {
      state.companies = payload;
    },
    distributors(state, payload) {
      state.distributors = payload;
    },
    partners(state, payload) {
      state.partners = payload;
    },
    name(state, payload) {
      state.name = payload;
    },
    profileProps(state, props) {
      const profile = { ...state.profile };

      Object.keys(props).forEach((field) => {
        profile[field] = props[field];
      });

      state.profile = profile;
    },
    setConnected(state){
      state.isConnected = true;
    }
    // validateNewPassword(state) {
    //   const password = state.profile.password;
    //   const passwordConfirmation = state.profile.password_confirmation;

    //   // try {
    //   //   if (password !== passwordConfirmation)
    //   //     throw new Error("The password confirmation failed!");
    //   //   if (
    //   //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
    //   //       password
    //   //     ) === false
    //   //   )
    //   //     throw new Error(
    //   //       "Password must contain minimum 8 symbols, 1 capital letter, 1 number and 1 special character."
    //   //     );
    //   // } catch (err) {
    //   //   throw err;
    //   // }
    // },
  },
  actions: {
    createPartner(_, partner) {
      return Profile.createPartner(partner);
    },
    getProfile({ commit }) {
      return Profile.get().then((profile) => {
        commit("profile", profile.data);
        return profile;
      });
    },
    updateProfile(_, body) {
      return Profile.updateProfile(body);
    },
    updatePassword(_, body) {
      return Profile.updatePassword(body);
    },

    setProfile({ commit }, body) {
      commit("profile", body);
    },
    getChoice: throttle(
      ({ commit }) =>
        Profile.getChoice().then((choices) => {
          commit("companies", choices["companies"]);
          commit("distributors", choices["distributors"]);
          commit("partners", choices["partners"]);
          return choices;
        }),
      100,
      { trailing: false }
    ),
    setPreferredLanguage({ state }, lang) {
      if (!state.profile) return false;
      const payload = {
        preferred_language: lang,
      };
      return Profile.setPreferredLanguage(payload);
    },
    join(_, [id, hash]) {
      return Profile.invite(id, hash);
    },
    resetState({ state }) {
      Object.assign(state, INITIAL_STATE);
    },
    setRole({ commit }, role) {
      localStorage.setItem(ROLE_KEY, role);
      commit("role", role);
    },
    checkProfile({ state }) {
      return !!state.profile;
    },
    chooseAccount(_, body) {
      Profile.chooseAccount(body);
    },
    connectBank(_, body) {
      return Profile.connectBank(body);
    },
    reconnectBank(_, body) {
      return Profile.reconnectBank(body);
    },
    connectBankCompleted(_, body) {
      return Profile.connectBankCompleted(body);
    },
    connectEconomic(_, body) {
      return Profile.connectEconomic(body);
    },
    sendEmail(_, body) {
      Profile.sendEmail(body);
    },
    getNordigenBanks() {
      return Profile.getNordigenBanks();
    },
  },
  getters: {
    isRequiredToVerify(state) {
      return state.profile && state.profile.is_verify_required;
    },
  },
};
