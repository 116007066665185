<template>
  <component
    class="btn font-medium flex items-center justify-center cursor-pointer focus:outline-none focus:shadow-outline whitespace-nowrap"
    :is="buttonType"
    :href="href"
    :type="type"
    :class="btnClasses"
    :id="id"
    :disabled="disabled"
  >
    <slot></slot>
  </component>
</template>
<script>
export default {
  props: {
    href: {
      required: false,
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "button", //button, submit
    },
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: "teal",
    },
    border: {
      type: String,
    },
    size: {
      type: String,
      default: "md", //sm, md, lg
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    outline: Boolean,
    transparent: Boolean,
    round: Boolean,
    class: {
      type: String,
      default: "",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    click: {},
  },
  computed: {
    colorClasses() {
      const color = this.color;
      let baseClasses = `bg-${color} text-black hover:bg-hoverB`;
      let outlineClasses = `bg-white hover:bg-hoverBout  border-${color} border`;
      if (this.transparent) outlineClasses += ` bg-transparent`;
      if (this.href) baseClasses = "text-black hover:text-hoverB ";
      if (this.disabled)
        return !this.outline
          ? `${
              !this.href && "bg-disabledB"
            } pointer-events-none text-disabledBT cursor-default`
          : `text-disabledBT pointer-events-none border-disabledOutB border cursor-default`;
      return this.outline ? outlineClasses : baseClasses;
    },
    sizeClasses() {
      const sizeMappings = {
        xsm: `h-6 px-2`,
        sm: `h-8 text-sm px-4`,
        md: `h-10 text-base px-6`,
        lg: `text-lg h-12 px-12`,
      };
      let sizeString = sizeMappings[this.size] || sizeMappings.md;
      return this.fullWidth ? sizeString + " w-full" : sizeString;
    },
    btnClasses() {
      const borderRadiusClasses = this.round ? "rounded-full" : "rounded";
      return `${this.colorClasses} ${this.sizeClasses} ${borderRadiusClasses} ${this.class} `;
    },
    buttonType() {
      if (this.href) {
        return "a";
      } else {
        return "button";
      }
    },
  },
};
</script>
