import http from "./http.service";
import {handleAllUrlFilters} from "../utils/helper";

class BankStatements {
    getBankStatementsByBankAccount({id, filters}) {
        let url = `/api/bank-statements/${id}`;
        return http
            .get(handleAllUrlFilters(url, filters))
            .then((res) => {
               
                if(filters['to_date'] || filters['from_date'] ){
                    res.data.data = res.data.data.sort((a,b)=>new Date(b.date) - new Date(a.date));
                }
                return {data: res.data, meta: res.meta};
            })
    }

    getBankStatementsInExcelDoc({currentBankAccount, filters, companyName}) {
        const {id: bankAccountId} = currentBankAccount;
        return http
            .get(handleAllUrlFilters(`/api/bank-statements/${bankAccountId}/export`, filters), {
                responseType: "arraybuffer",
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]), {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8",
                });
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${companyName}.xlsx`);
                link.dataset.downloadurl = [
                    "text/plain",
                    link.download,
                    link.href,
                ].join(":");
                link.click();
                link.remove();
            });
    }
}

export default new BankStatements();
