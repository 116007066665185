<template>
  <svg
    viewBox="0 0 34 80"
    fill="none"
    height="80px"
    width="34px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H13.5204C16.8232 0 19.7865 2.02973 20.9799 5.10943L33.3799 37.1094C34.1005 38.9691 34.1005 41.0309 33.3799 42.8906L20.9799 74.8906C19.7865 77.9703 16.8232 80 13.5204 80H8C3.58172 80 0 76.4183 0 72V8Z"
      fill="currentColor"
    />
  </svg>
</template>
