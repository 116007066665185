import ExportService from "@/services/export.service";
import { downloadDataFormatFileFromResponse } from "@/utils/helper";


export default {
	namespaced: true,
	state: {
		exportData: [],
		meta: null,
		allSelectedForExport: {},
		checkedAll: false,
		filter: {},
		pressed: 0,
		loading:false
	},
	mutations: {
		resetState(state){
			state.exportData =[]
			state.meta = null
			state.allSelectedForExport={}
			state.checkedAll = false
			state.filter = {}
			state.pressed =0
			state.loading = false
		},
		setData(state, payload) {
			state.exportData = payload;
		},
		setMeta(state, payload) {
			state.meta = payload;
		},
		setFilter(state,filter){
			const filterKey = Object.keys(filter)[0];
			if(state.filter[filterKey]) return state.filter = {};
			state.filter = filter
		},
		setLoading(state,payload){
			state.loading = payload
		},
		
		setSelectedForExport(state, { id, checked, invoiceMatches }) {
			
			if (!checked) {
				delete state.allSelectedForExport[id];
				const lengthOfCheckboxes = Object.keys(state.allSelectedForExport).length
				if(!lengthOfCheckboxes){
					state.checkedAll = false;
				}
			} else{
			
			state.allSelectedForExport[id] = {
				checked,
				invoiceMatches: invoiceMatches || [],
			};
			const lengthOfCheckboxes = Object.keys(state.allSelectedForExport).length
		
			if(state.exportData.length === lengthOfCheckboxes){
				state.checkedAll = true;
			}}
		},
		resetAllSelected(state) {
			state.allSelectedForExport = new Object();
		},
		checkedAll(state, payload) {
			state.checkedAll = payload;
		},
	},
	actions: {
		setFilter({commit,dispatch},status){
			commit("setLoading",true)
			commit("checkedAll", false);
			commit("resetAllSelected");
			commit('setFilter',status);
			
			dispatch('getDataForExportPage');
		},
		async getDataForExportPage({ commit,state }) {
			commit("checkedAll", false);
			commit("resetAllSelected");
		
			return ExportService.getDataForExportPage(state.filter).then((res) => {
				if(!res.data.data.length){
					commit('setLoading','no-data')
				}else{
					commit("setLoading",false)
				}
				commit("setData", res.data.data);
				commit("setMeta", res.data.meta);
				
			});
			
		},
		selectAllForExport({ commit }, {  isChecked,data }) {
			if (isChecked) {
				commit("setSelectedForExport", {});
				for (let i = 0; i < data.length; i++) {
					if (data[i].status == "ready_export") commit("setSelectedForExport", {
						id: data[i].id,
						checked: true,
						invoiceMatches: data[i].invoiceMatches,
					});
					
				}
				/* if(Object.keys(state.allSelectedForExport).length === 0){
					commit('checkedAll',true);
					setTimeout(()=>commit('checkedAll',false),10)
				}else{ */
					commit("checkedAll", true);
				/* } */
			} else {
				commit('checkedAll',false);
				commit("resetAllSelected");
			}
		},
		async exportOneToEconomics({rootState,dispatch,state},invoice){
			const iconomicConnected = rootState.profile.profile.economic_connected;
			await ExportService.exportToEconomics(
				invoice
			).then(res=>{
				if(!iconomicConnected){
						let filename = "economic-export";
						downloadDataFormatFileFromResponse(res, filename + ".csv");
					}
			})

			dispatch("getDataForExportPage", state.filter);
			return;
			
		},
		async exportAllSelectedToEconomics({ state, dispatch, commit, rootState  }) {
			const InvoicesForExport = Object.keys(state.allSelectedForExport);
			const iconomicConnected = rootState.profile.profile.economic_connected;
			try {
				for (let i = 0; i < InvoicesForExport.length; i++) {
					const invoiceId = InvoicesForExport[i];
					if (state.allSelectedForExport[invoiceId]) {
						for (
							let j = 0;
							j < state.allSelectedForExport[invoiceId].invoiceMatches.length;
							j++
						) {
							
							await ExportService.exportToEconomics(
								state.allSelectedForExport[invoiceId].invoiceMatches[j]
							).then(res=>{
								
								if(!iconomicConnected){
										let filename = "economic-export";
			
										if (state.allSelectedForExport[invoiceId].invoiceMatches.length > 1) {
											filename = `${filename}-${++i}`;
										}
										downloadDataFormatFileFromResponse(res, filename + ".csv");
									}
							})
						}
					}
				}
				commit("resetAllSelected");
				commit("checkedAll", false);
				dispatch("getDataForExportPage", state.filter);
				return true;
			} catch (err) {
				return false;
			}
		},
		exportFileToCsv(_, body) {
			try {
				for (let i = 0; i < body.length; i++) {
					const invoiceMatch = body[i].invoiceMatches[0];
					ExportService.exportToEconomics(invoiceMatch).then((res) => {
						setTimeout(() => {
							let filename = "economic-export";

							if (body.length > 1) {
								filename = `${filename}-${++i}`;
							}
							downloadDataFormatFileFromResponse(res, filename + ".csv");
						}, 500);
					});
				}
				return true;
			} catch (err) {
				return true;
			}
		},
		exportToCsv() {
			return ExportService.exportToCsv();
		},
	},
};
