import CreditCards from "@/services/creditCards.service";

export default {
  namespaced: true,
  state: {
    userCreditCards: null,
    newCreditCard: { card_number: "", name: "" },
  },
  mutations: {
    getUserCreditCards(state, payload) {
      state.userCreditCards = payload;
    },
    newCreditCard(state, payload) {
      state.newCreditCard = { ...state.newCreditCard, ...payload };
    },
    resetNewCreditCard(state) {
      state.newCreditCard = { card_number: "", name: "" };
    },
  },
  actions: {
    getUserCreditCards({ commit }) {
      CreditCards.getCurrentUserCreditCards().then((res) => {
        for (let j = 0; j < res.data.length; j++) {
          let card_number = "";

          for (let i = 0; i < res.data[j].card_number.length; i++) {
            if (i % 4 === 0 && i !== 0) {
              card_number += " ";
            }
            if (i >= 4 && i < 12) {
              card_number += "x";
            } else {
              card_number += res.data[j].card_number[i];
            }
          }
          res.data[j].card_number = card_number;
        }

        commit("getUserCreditCards", { ...res.data });
      });
    },
    createCreditCard({ state, commit }) {
      return CreditCards.createCreditCard({ ...state.newCreditCard }).then(
        (res) => {
          commit("resetNewCreditCard");
          return res.data;
        }
      );
    },
    deleteCreditCard(_, id) {
      return CreditCards.deleteCreditCard(id);
    },
  },
};
