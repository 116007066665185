import http from "./http.service";

class Labels {
  getLabelsForCurrentCompany() {
    return http.get("/api/labels");
  }
  createNewLabel(data) {
    let payload;
    if (data.dimension.id) {
      payload = { ...data };
    } else {
      payload = { ...data, dimension: "" };
    }
    return http.post("/api/labels", {
      ...payload,
      dimension_id: payload.dimension.id,
    });
  }
  updateLabel({ id, ...data }) {
    return http.post(`/api/labels/${id}`, {
      ...data,
      dimension_id: data.dimension.id,
    });
  }
  deleteLabel({ id }) {
    return http.delete(`/api/labels/${id}`);
  }
}

export default new Labels();
