import Document from "@/services/documents.service";
import { format } from "date-fns";

const emptyDocument = {
  vendor: "",
  date: "",
  due_date: null,
  amount: null,
  invoice_number: null,
};
export default {
  namespaced: true,
  state: {
    documents: null,
    newDocument: { ...emptyDocument },
    singleDoc: null,
  },
  mutations: {
    documents(state, payload) {
      state.documents = payload;
    },
    newDocument(state, payload) {
      state.newDocument = payload;
    },
    setDocumentPropery(state, payload) {
      const payloadKey = Object.keys(payload);
      state.newDocument[payloadKey] = payload[payloadKey];
    },
    singleDoc(state, payload) {
      state.singleDoc = payload;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("newDocument", { ...emptyDocument });
      commit("documents", null);
    },
    createDocumentByUpload(_, file) {
      return Document.createDocumentByUpload(file);
    },
    createNewDocument({ state }) {
      let docToSend = { ...state.newDocument };
      if (docToSend["date"]) { 
        docToSend["date"] = format(
        new Date(docToSend.date),
        "dd.MM.yyyy hh:mm:ss"
      );
      }

      if (docToSend["due_date"]) {
        docToSend["due_date"] = format(
            new Date(docToSend.due_date),
            "dd.MM.yyyy hh:mm:ss"
        );
      }


      // this fixes where empty amount * 100 is 0 and make BE validation pass with valid amount - JS is weird
      if (docToSend.amount) {
        docToSend.amount = Number(docToSend.amount) * 100;
      }

      return Document.createNewDocument(docToSend);
    },
    getAllDocuments({ commit }) {
      Document.getDocumentsForSelectedCompany().then((res) => {
        commit("documents", res.data);
      });
    },
    getSingleDocument({ commit }, docId) {
      Document.getDocument(docId).then((res) => {
        commit("singleDoc", res.data);
      });
    },
    updateSingleDocument({ dispatch }, body) {
      return Document.updateDocument(body).then(() => {
        dispatch("getSingleDocument", body.id);
      });
    },
    updateDocumentInbox(_, body) {
      return Document.updateDocument(body);
    },
    deleteDocument(_, documentId) {
      return Document.deleteDocument(documentId);
    },
    assignUserToDocument(_, { documentId, responsibleId }) {
      return Document.assignUserToDocument(documentId, responsibleId);
    },
    AssignUserToTransaction(_, { transactionId, responsibleId }) {
      return Document.assignUserToTransaction(transactionId, responsibleId);
    },
  },
};
