<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1666 7.99919L14.5833 0.0825195H5.41658L0.833252 7.99919L5.41658 15.9159H14.5833L19.1666 7.99919ZM6.37738 1.75H13.6224L17.2407 8L13.6224 14.25H6.37738L2.75905 8L6.37738 1.75ZM9.16654 10.5H10.8332V12.1667H9.16654V10.5ZM10.8332 3.83265H9.16654V8.83265H10.8332V3.83265Z"
      fill="#DF2040"
    />
  </svg>
</template>
