<template>
  <div
    class="mt-16 sm:mt-24 md:mt-44 h-10/12 md:h-fit w-10/12 flex flex-col md:flex-row md:w-3/4 xl:w-7/12 2xl:w-6/12"
  >
    <div
      class="flex justify-center items-center md:w-1/2 bg-primary rounded-l-lg p-8"
    >
    <LandingSVG />
     <!--  <img src="../../assets/Landing.png" alt="Join Page img" /> -->
    </div>
    <div
      class="flex flex-col justify-center items-center md:w-1/2 rounded-r-lg px-7 py-7 md:py-0 md:px-14 bg-white"
    >
      <p class="text-lg text-darkText font-bold mb-12">{{ $t("welcome") }}</p>
      <form class="w-full">
        <BasicInput
          type="email"
          :label="$t('email') /* Email */"
          :fullWidth="true"
          field="email"
          :placeholder="$t('email') /* Email */"
          :errors="errors"
          :value="email"
          @input="changeEmail($event)"
        />
        <BasicButton
          :disabled="loading /* || disabled */"
          color="primary"
          :fullWidth="true"
          type="submit"
          class="mt-4"
          @click.prevent="checkEmail"
          @keyup.enter="checkEmail"
        >
          <p class="font-bold uppercase">{{ $t("continue") }}</p>
        </BasicButton>
      </form>
    </div>
  </div>
</template>

<script>
import BasicInput from "../../components/basic/BasicInput";
import BasicButton from "../../components/basic/BasicButton";
import LandingSVG from "@/svg/LandingSVG.vue";
/* import { RFC2822EMAILREGEX } from "@/utils/variables"; */
import Errors from "../../utils/errors";
export default {
  name: "landing-page",
  i18nOptions: { namespaces: "landing-page" },
  components: {
    BasicInput,
    BasicButton,LandingSVG
  },
  data() {
    return {
      email: null,
      errors: new Errors(),
      loading: false,
      disabled: false,
    };
  },
  computed: {
    prefilled_user() {
      return this.$route.query.prefilled_user
        ? this.$route.query.prefilled_user
        : undefined;
    },
    invitation_hash() {
      return this.$route.query.invitation_hash
        ? this.$route.query.invitation_hash
        : undefined;
    },
  },
  methods: {
    checkEmail() {
      this.errors.clear();
      if (this.email?.length === 0 || !this.email) {
        return this.errors.set({ email: [this.$t("not-valid-email")] });
      }
      this.loading = true;
      this.$store.commit("auth/email", this.email);
      if (this.prefilled_user !== undefined) {
        this.loading = false;
        this.$router.push(`/signup?prefilled_user=${this.prefilled_user}`);
      } else {
        this.$store
          .dispatch("auth/checkEmail")
          .then((res) => {
            if (res.exist) {
              localStorage.setItem("email", this.email);
              this.invitation_hash
                ? this.$router.push(
                    `/login?invitation_hash=${this.invitation_hash}`
                  )
                : this.$router.push("/login");
            } else {
              this.invitation_hash
                ? this.$router.push(
                    `/signup?invitation_hash=${this.invitation_hash}`
                  )
                : this.$router.push("/signup");
            }
          })
          .catch((err) => {
            return this.errors.set(err.response.data.errors);
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    changeEmail(payload) {
      this.email = payload;
    },
  },
  watch:{
      email(newEmail){
        if(newEmail?.length > 0){
          this.errors.clear()
        }
      }
  },

  mounted() {
   /*  localStorage.removeItem("email");
    localStorage.removeItem("loggedIn"); */
    document.title = this.$t("title");
  },
};
</script>
