import http from "./http.service";

class CreditCards {
  getCurrentUserCreditCards() {
    return http.get("/api/user/credit-cards").then((res) => {
      return res.data;
    });
  }
  createCreditCard(data) {
    return http.post("/api/user/credit-cards", data).then((res) => res);
  }
  deleteCreditCard(id) {
    return http.delete(`/api/user/credit-cards/${id}`).then((res) => res);
  }
}

export default new CreditCards();
