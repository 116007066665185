import { downloadPdf } from "@/services/pdf.service";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    download(_, data) {
      return downloadPdf(data);
    },
  },
};
