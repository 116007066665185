import axios from "axios";
import axiosRetry from "axios-retry";
// import app from "../main";
import Notifications from "./notifications.service";
import store from "../store/store";

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API,
});

axiosRetry(instance, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    if (
      error.response &&
      [409, 401, 403, 404, 422].includes(error.response.status)
    ) {
      return false;
    }
    return false;
  },
});

instance.interceptors.request.use(
  (request) => {
    // Set locale
    // request.headers.Language = app.$i18n.i18next.language;

    return request;
  },
  (error) => {
    if (
      typeof error.response === "undefined" ||
      typeof error.response.status === "undefined"
    ) {
      Notifications.notify("warning", "check-connection", "Network Error");
      return Promise.reject(error);
    }
  }
);
instance.interceptors.response.use(
  (response) => {
    // Show Api errors
    if (typeof response.data.ok !== "undefined") {
      if (!response.data.ok) {
        Notifications.notify("warning", response.data.desc, "Error", 0);
        return Promise.reject(response.data.desc);
      }
    }

    return response;
  },
  (error) => {
    if (
      typeof error.response === "undefined" ||
      typeof error.response.status === "undefined"
    ) {
      Notifications.notify("warning", "check-connection", "network-error");
      return Promise.reject(error);
    }

    // Form validation errors
    if (error.response.status === 422) {
      const errorsList = error.response.data.errors;
      const errorMessage = errorsList[Object.keys(errorsList)[0]];
      Notifications.notify(
        "warning",
        errorMessage[0] || error.response.data.message,
        "Incorrect Form"
      );
      return Promise.reject(error);
    }

    // Authentication issues
    if (error.response.status === 401 || error.response.status === 419) {
      const isLoggedIn = localStorage.getItem("loggedIn");

      if (isLoggedIn) {
        let message = null;
        if (localStorage.getItem("i18nextLng") !== "en") {
          message = "Du er blevet logget ud på grund af inaktivitet.";
        } else {
          message = "You have been logged out due to inactivity.";
        }
        if (localStorage.getItem("i18nextLng") !== "en") {
        Notifications.notify("info", message, "uautoriseret");
        } else {
        Notifications.notify("info", message, "unauthorized");

        }
        store.dispatch("auth/forceLogout");
      }

      return Promise.reject(error);
    }

    // Api errors that are returned with error codes
    Notifications.notify(
      "warning",
      error.response.data[
        // eslint-disable-next-line no-prototype-builtins
        error.response.data.hasOwnProperty("message") ? "message" : "error"
      ],
      "Error"
    );
    return Promise.reject(error);
  }
);

export default instance;
