import http from "./http.service";

class DocumentsRules {
  getDocumentsRulesForCurCompany() {
    return http.get("/api/document-rules").then((res) => res.data);
  }
  createNewDocRule(body) {
    return http.post("/api/document-rules", body);
  }
  getDocRuleTypes() {
    return http.get("/api/document-rules/types");
  }
  getCurrentDoc(id) {
    return http.get(`/api/document-rules/${id}`).then((res) => res.data);
  }
  editCurrentRule({ id, ...rest }) {
    return http.post(`/api/document-rules/${id}`, rest);
  }
  deleteCurrentRule({ id }) {
    return http.delete(`/api/document-rules/${id}`);
  }
}
export default new DocumentsRules();
