import http from "./http.service";

class TransactionsRules {
  curentCompanyTransactionRules() {
    return http.get("/api/transaction-rules").then((res) => res.data);
  }
  transactionRuleTypes() {
    return http.get("/api/transaction-rules/types");
  }
  transactionRulesCurrentCompany() {
    return http.get("/api/transaction-rules");
  }
  createTransactionRule(body) {
    return http.post("/api/transaction-rules", body);
  }
  getCurrentDoc(id) {
    return http.get(`/api/transaction-rules/${id}`).then((res) => res.data);
  }
  editCurrentRule({ id, ...rest }) {
    return http.post(`/api/transaction-rules/${id}`, rest);
  }
  deleteCurrentRule({ id }) {
    return http.delete(`/api/transaction-rules/${id}`);
  }
}
export default new TransactionsRules();
