import BankStateMentService from "@/services/bankStatement.service";

export default {
  namespaced: true,
  state: {
    bankStatementsByBankAccount: null,
  },
  mutations: {
    setBankStatementsByBankAccount(state, payload) {
      state.bankStatementsByBankAccount = payload;
    },
  },
  actions: {
    getBankStatementsByBankAccount({ commit }, body) {
      return BankStateMentService.getBankStatementsByBankAccount(body).then(
        (res) => {
          commit("setBankStatementsByBankAccount", res.data);
          return res.data;
        }
      );
    },
    getBankStatementsInExcelDoc(_, body) {
      return BankStateMentService.getBankStatementsInExcelDoc(body);
    },
  },
};
