<template>
	<div
		class="pt-16 min-h-fit w-10/12 flex flex-col md:flex-row md:w-3/4 xl:w-7/12 2xl:w-1/2"
	>
		<div
			class="flex justify-center items-center md:w-2/5 bg-primary rounded-l-lg"
		>
			<!-- <img src="../../assets/SignUp.png" alt="Sign Up Page img" /> -->
			<SignupPageSVG />
		</div>
		<div
			class="flex flex-col justify-center items-center md:w-3/5 rounded-r-lg px-7 py-14 md:px-14 bg-white"
		>
			<p class="text-lg text-darkText font-bold mb-5">
				{{ $t("new-user")
				}}<!-- You are a new user -->
			</p>
			<p class="text-xbase text-darkText mb-5">
				{{ $t("to-sign-up")
				}}<!--  To sign up, fill in the data below -->
			</p>
			<form autocomplete="off">
				<div class="w-full">
					<div class="flex mb-5 justify-between flex-col sm:flex-row">
						<BasicInput
							id="fname"
							allClass="sm:mr-3"
							:errors="errors"
							field="name"
							:label="$t('first-name') /* First name */"
							:placeholder="$t('first-name') /* First name */"
							autofill="false"
							:value="user.first_name"
							@input="updateUser({ first_name: $event })"
						/>
						<BasicInput
							id="name"
							allClass="sm:ml-3"
							type="text"
							:label="$t('last-name') /* Last name */"
							field="name"
							:placeholder="$t('last-name') /* Last name */"
							autocomplete="new-password"
							:value="user.last_name"
							@input="updateUser({ last_name: $event })"
						/>
					</div>
					<!-- <div class="mb-5">
            <BasicInput
              id="phone"
              label="Phone"
              :fullWidth="true"
              :errors="errors"
              field="phone_number"
              placeholder="Phone"
              :value="user.phone_number"
              @input="updateUser({ phone_number: $event })"
            />
          </div> -->
					<div class="mb-5">
						<BasicInput
							id="password"
							:label="$t('password') /* Password */"
							:fullWidth="true"
							:errors="errors"
							field="password"
							autocomplete="new-password"
							:placeholder="$t('password') /* Password */"
							:value="user.password"
							@input="updateUser({ password: $event })"
						/>
					</div>
					<div class="mb-5">
						<BasicInput
							id="password1"
							:label="$t('confirm-password') /* Confirm password */"
							:fullWidth="true"
							:placeholder="$t('confirm-password') /* Confirm password */"
							:value="user.password_confirmation"
							@input="updateUser({ password_confirmation: $event })"
						/>
					</div>
					<BasicCheckbox
						@change="changeCheckbox"
						url="https://orprio.com/terms"
						:label="$t('accept-terms') /* I accept the terms and conditions */"
						class="underline underline-offset-2"
						labelClass="cursor-pointer"
					/>
				</div>
				<div class="w-full flex justify-center">
					<BasicButton
						:disabled="loading || !accept_terms"
						type="submit"
						color="primary"
						class="mt-6"
						@click.prevent="signup"
						@keyup.enter="signup"
					>
						<p class="font-bold uppercase">
							{{ $t("continue")
							}}<!-- CONTINUE -->
						</p>
					</BasicButton>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import BasicInput from "../../components/basic/BasicInput";
import BasicButton from "../../components/basic/BasicButton";
import BasicCheckbox from "../../components/basic/BasicCheckbox";
import Notifications from "../../services/notifications.service";
import Errors from "@/utils/errors";
import SignupPageSVG from "@/svg/pages/SignupPageSVG.vue";

const emptyUser = {
	email: "",
	password_confirmation: "",
	password: "",
	first_name: "",
	last_name: "",
};

export default {
	name: "landing-page",
	i18nOptions: { namespaces: "signup" },
	components: {
		BasicInput,
		BasicButton,
		BasicCheckbox,
		SignupPageSVG,
	},
	data() {
		return {
			user: { ...emptyUser },
			errors: new Errors(),
			invitation_hash: this.$route.query.invitation_hash,
			loading: false,
			accept_terms: false,
			companyName: this.$route?.query?.company || null,
		};
	},
	computed: {
		emailFromEmail() {
			return this.$route?.query?.email
				? this.$route.query.email.replaceAll(" ", "+")
				: undefined;
		},
		prefilled_user() {
			return this.$route.query.prefilled_user
				? this.$route.query.prefilled_user
				: undefined;
		},
	},
	mounted() {
		document.title = this.$t("title");
	},
	methods: {
		changeCheckbox() {
			this.accept_terms = !this.accept_terms;
		},
		updateUser(payload) {
			const payloadKey = Object.keys(payload);
			this.user[payloadKey] = payload[payloadKey];
		},
		signup() {
			this.loading = true;
			if (this.emailFromEmail) {
				this.$store.dispatch("auth/setEmail", this.emailFromEmail);
			}

			this.user["invitation_hash"] = this.invitation_hash;
			this.$store
				.dispatch("auth/signup", [this.user, this.prefilled_user])
				.then((res) => this.success(res))
				.catch((err) => this.errors.set(err.response.data.errors))
				.finally(() => {
					this.loading = false;
				});
		},
		success() {
			if (this.prefilled_user && this.emailFromEmail) {
				localStorage.setItem("loggedIn", true);
				if (this.companyName) {
					Notifications.notify(
						"success",
						this.$t("successfully-joined") +
							` ${this.companyName} `.replaceAll("-", " ") +
							this.$t("company")
					);
				}
				else {
					Notifications.notify("success", this.$t("welcome-to-orprio"));
				}

				// /* "Welcome to Orprio" */
				this.$router.push("/wizard?id=0");

				return;
			}
			// Notifications.notify("success", this.$t("confirmation-email"));
			/* "Confirmation email has been sent , please check your inbox for further steps!" */
			this.user = {
				...emptyUser,
			};
			this.$router.push("/confirm");
			//this.$store.dispatch("auth/isAuthenticated");
		},
	},
};
</script>
