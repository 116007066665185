import http from "./http.service";

class Document {
  createNewDocument(body) {
    return http.post("/api/documents", body);
  }

  getDocumentsForSelectedCompany() {
    return http.get("/api/documents").then((res) => res.data);
  }

  getDocument(docId) {
    return http.get(`/api/documents/${docId}`).then((res) => res.data);
  }

  createDocumentByUpload(body) {
    return http.post("/api/documents/upload-file", body);
  }

  updateDocument(updatedDocument) {
    return http.post(`/api/documents/${updatedDocument.id}`, updatedDocument);
  }

  deleteDocument(documentId) {
    return http.delete(`/api/documents/${documentId}`);
  }

  assignUserToDocument(documentId, responsibleId) {
    return http.post(`/api/documents/${documentId}/assign-user/`, {
      responsible_id: responsibleId,
    });
  }
  assignUserToTransaction(transactionId, responsibleId) {
    return http
      .post(`/api/transactions/${transactionId}/assign-user`, {
        responsible_id: responsibleId,
      })
      .then(() => true)
      .catch(() => false);
  }
}

export default new Document();
