import { createStore } from "vuex";
import language from "./language";
import auth from "./auth";
import profile from "./profile";
import Auth from "../services/auth.service";
import distributor from "./distributor";
import company from "./company";
import partner from "./partner";
import navbar from "./navbar";
import documents from "./documents";
import labels from "./labels";
import dimensions from "./dimensions";
import creditCards from "./creditCards";
import transactionsRules from "./transactionRules";
import documentsRules from "./documentsRules";
import bankStatements from "./bankStatements";
import pdf from "./pdf";
import inbox from "./inbox";
import exportState from "./export";
import currency from "./currency";

const store = createStore({
  modules: {
    language,
    exportState,
    auth,
    profile,
    distributor,
    documents,
    company,
    partner,
    navbar,
    labels,
    dimensions,
    creditCards,
    transactionsRules,
    documentsRules,
    bankStatements,
    pdf,
    inbox,
    currency,
  },
  state: {},
  mutations: {},
  actions: {
    init({ dispatch }) {
      dispatch("language/initLanguage");
      Auth.isAuthenticated();
    },
  },
  getters: {},
});

export default store;
