export default class Errors {
  constructor(errors = {}, notices) {
    this.errors = errors;
    this.notices = notices;
  }

  setNotices(notices) {
    this.notices = notices;
  }

  getNotice(field) {
    if (this.hasNotice(field)) {
      return this.notices[field];
    }
  }

  hasNotice(field) {
    // eslint-disable-next-line no-prototype-builtins
    return this.notices && this.notices.hasOwnProperty(field);
  }

  clearNotices() {
    this.notices = {};
  }

  set(errors) {
    this.errors = errors;
  }

  setErrorToPropery(error) {
    // error = {property:value}
    this.errors = { ...this.errors, ...error };
  }

  clearErrorPropery(property) {
    Object.prototype.hasOwnProperty.call(this.errors, property) &&
      delete this.errors[property];
  }

  get(field) {
    if (this.has(field)) {
      return this.errors[field];
    }
    return null;
  }

  has(field) {
    // eslint-disable-next-line no-prototype-builtins
    return this.errors && this.errors.hasOwnProperty(field);
  }

  clear() {
    this.errors = {};
  }
}
