import Currency from "@/services/currency.service";

export default {
  namespaced: true,
  state: {
    currencies: null
  },
  mutations: {
    currencies(state, payload){
        state.currencies = payload;
    }
  },
  actions: {
    getAllCurrencies({ commit }) {
      return Currency.getAllCurrencies().then((res) => {
        commit('currencies', res.data)
      });
    },
  },
};
