<template>
	<div
		class="mt-16 sm:mt-24 md:mt-44 h-10/12 md:h-2/5 w-10/12 flex flex-col md:flex-row md:w-3/4 xl:w-7/12 2xl:w-6/12"
	>
		<div class="flex justify-center items-center w-2/5 bg-primary rounded-l-lg">
			<!-- <img src="../../assets/JoinPageIMG.png" alt="Join Page img" /> -->
			<JoinPartnerPageSvg />
		</div>
		<div
			class="flex flex-col justify-center items-center w-3/5 rounded-r-lg px-7 py-7 md:py-0 md:px-14 bg-white"
		>
			<p v-if="pu.company" class="text-lg text-darkText text-center font-bold mb-6">
				{{ $t("join")
				}}<!-- Join -->
				{{ pu.company }}
			</p>
			<p v-if="!pu.company" class="text-lg text-darkText text-center font-bold mb-6">
				{{ $t("join")
				}}<!-- Join -->
				{{ pu.partner_name }}
			</p>
			<div class="w-3/4" 	v-if="pu.company">
				<p class="text-base font-normal text-center mb-6">
					{{ $t("invitation-first-part") }}
					<!-- You have been invited by -->
					{{ pu.name }} {{ $t("to")}}<!-- to -->
					{{ pu.company }}. {{ $t("confirm")}}<!-- Please confirm by clicking the button below -->.
				</p>
			</div>
			<div class="w-3/4" v-if="pu.partner_name">
				<p class="text-base font-normal text-center mb-6">
					{{ $t("invitation-first-part") }}
					<!-- You have been invited by -->
					{{ pu.name }} {{ $t("to")}}<!-- to -->
					{{ pu.partner_name }}. {{ $t("confirm")}}<!-- Please confirm by clicking the button below -->.
				</p>
			</div>
			<div class="w-full flex justify-center">
				<BasicButton
					color="primary"
					:fullWidth="false"
					@click.prevent="goToLogin"
				>
					<p class="font-bold uppercase">
						{{ $t("accept")
						}}<!-- YES, I WANT TO JOIN -->
					</p>
				</BasicButton>
			</div>
		</div>
	</div>
</template>

<script>
import BasicButton from "../../components/basic/BasicButton";
import JoinPartnerPageSvg from "@/svg/pages/JoinPartnerPageSvg.vue";
import Auth from "@/services/auth.service";

export default {
	i18nOptions: { namespaces: "join-partner" },
	name: "join-p-page",
	data() {
		return {
			pu: this.$route.query.pu,
		};
	},
	mounted() {
		if (this.pu) {
			this.pu = JSON.parse(atob(this.pu));
		}
	},
	components: {
		BasicButton,
		JoinPartnerPageSvg,
	},
	methods: {
		async goToLogin() {
			localStorage.setItem("hash", this.pu.invitation_hash);
			if (this.pu.prefilled_user) {
				localStorage.setItem("email", this.pu.email);

				const emailExists = await Auth.checkEmail(this.pu.email);

				if (emailExists.password_change) {
					this.$router.push(
						`/signup?prefilled_user=${this.pu.prefilled_user}&email=${this.pu.email}`,
					);
					return;
				}

				if (!emailExists.exist) {
					this.$router.push(
						`/signup?prefilled_user=${this.pu.prefilled_user}&email=${this.pu.email}`,
					);
				} else {
					this.$router.push(
						`/login?prefilled_user=${this.pu.prefilled_user}&email=${this.pu.email}`,
					);
				}
				return;
			}
			try {
				// await Auth.logout();
			}
				// eslint-disable-next-line no-empty
			catch (e) {

			}
			this.$router.push(`/?invitation_hash=${this.pu.invitation_hash}`);
		},
	},
};
</script>
