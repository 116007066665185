/* eslint-disable no-prototype-builtins */
import Auth from "../services/auth.service";
import app from "../main";
import EmailService from "@/services/email.service";
import i18next from "i18next";

export default {
  namespaced: true,
  state: {
    email: null,
    redirect: null,
    isAuthenticated: null,
    profile: null,
  },
  mutations: {
    profile(state, payload) {
      state.profile = payload;
    },
    email(state, payload) {
      state.email = payload;
    },
    redirect(state, payload) {
      state.redirect = payload;
    },
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
  },
  actions: {
    emailVerification(_, email) {
      return EmailService.emailVerification(email);
    },
    checkEmail({ state }) {
      return Auth.checkEmail(state.email);
    },
    setEmail({ commit }, body) {
      localStorage.setItem("email", body);
      commit("email", body);
    },
    login({ state }, user) {
      return Auth.login({
        email: state.email,
        language: i18next.language,
        ...user,
      });
    },
    signup({ state }, [user, hash]) {
      return Auth.register(
        { ...user, email: state.email, language: i18next.language },
        hash
      );
    },
    authSuccess({ commit, dispatch }, payload) {
      commit("isAuthenticated", payload.isAuthenticated);
      dispatch("redirect", payload.redirect);
    },
    redirect({ state, commit }, payload) {
      if (state.redirect) {
        app.$router.push({ name: state.redirect });
        commit("redirect", null);
        return;
      }
      if (payload) {
        return app.$router.push({ name: payload });
      }
      /*  return app.$router.push(
        payload ? { name: payload } : app.$router.currentRoute
      ); */
    },
    async logout2() {
      await Auth.logout();
    },
    async logout() {
      await Auth.logout().finally(() => {
        app.$router.push({ name: "landing" });
        /* window.location.href = "/"; */
      });
    },
    isAuthenticated() {
      return Auth.isAuthenticated();
    },
    resetState({ commit }) {
      commit("isAuthenticated", { isAuthenticated: false, redirect: null });
      commit("profile", null);
    },
    forceLogout() {
      Auth.forceLogout();
    },
  },
};
