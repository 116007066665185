import debounce from "lodash.debounce";
import memoize from "lodash.memoize";
import { codes, languages } from "./variables";
import { format } from "date-fns";

export function memoizeDebounce(func, wait = 0, options = {}) {
  const mem = memoize(function () {
    return debounce(func, wait, options);
  }, options.resolver);
  return function () {
    mem.apply(this, arguments).apply(this, arguments);
  };
}

export function encodeCountry(name) {
  return languages[name];
}

export function decodeCountry(code) {
  return codes[code];
}

export function encodeObject(object) {
  return btoa(JSON.stringify(object));
}

export function getDCPById(id, array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) return array[i];
  }
  return null;
}

export function handleAllUrlFilters(url, filters = []) {
  /*  if (filters["from_date"]) {
      filters["from_date"] = format(
        new Date(...filters["from_date"].split("-")),
        "yyyy-MM-dd"
      );
    }
    if (filters["to_date"]) {
      filters["to_date"] = format(
        new Date(...filters["to_date"].split("-")),
        "yyyy-MM-dd"
      );
    } */

  const filterEntries = Object.entries(filters);
  for (let i = 0; i < filterEntries.length; i++) {
    const key = filterEntries[i][0];
    const value = filterEntries[i][1];
    if (value) {
      if (!url.includes("?filter")) {
        url += "?filter";
      } else {
        url += "&filter";
      }

      if (key === "status") {
        url += `[status_${value}]=${true}`;
      } else if (key === "date") {
        url += `[exact_date]=${value}`;
      } else {
        url += `[${key}]=${value}`;
      }
    }
  }
  return url;
}

export function handlePermissionsForButtons(owner, accountant, permissions) {
  if (owner) return false;
  if (accountant) return false;
  return permissions;
}

export function isObject(a) {
  return !!a && a.constructor === Object;
}

export function dateFormatter(date) {
  if (!date) return;
  try {
    return format(new Date(date), "dd.MM.yyyy");
  } catch (e) {
    return "-";
  }
}

export function downloadDataFormatFileFromResponse(res, filename) {
  const url = window.URL.createObjectURL(new Blob([res.data]), {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8",
  });
  const link = document.createElement("a");
  link.href = url;
  link.id = filename + new Date().getMilliseconds();
  link.setAttribute("download", filename);
  link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");
  link.click();
  link.remove();
}
