<template>
	<svg
		width="177"
		height="138"
		viewBox="0 0 177 138"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M80.7995 119.393C75.6413 119.408 70.4852 119.526 65.3355 120.074C63.394 120.281 61.4927 120.736 59.547 120.918C55.6599 121.285 51.8447 121.967 47.9427 122.284C44.8021 122.539 41.6488 122.688 38.5167 123.01C35.5812 123.313 32.3307 123.242 29.4164 122.743C27.2592 122.375 25.0936 122.166 22.9723 121.572C20.5994 120.908 18.0235 120.434 15.5512 120.451C12.0257 120.475 8.59328 120.477 5.22216 121.649C4.03783 122.061 2.58914 122.737 1.69031 123.6C-0.367462 125.577 -0.646625 130.508 1.40904 132.557C4.87109 136.007 12.2584 137.36 17.1099 137.424C22.4267 137.494 27.5912 137.116 32.9165 136.894C37.8632 136.687 42.7993 136.393 47.7545 136.404C57.8763 136.425 68.0002 136.404 78.103 135.756C87.5946 135.148 97.1115 135.357 106.626 135.357C116.382 135.357 126.073 135.429 135.757 136.671C139.976 137.211 144.275 137.401 148.518 137.78C152.316 138.119 155.966 137.774 159.75 137.459C162.927 137.196 166.082 136.414 168.969 135.094C171.253 134.049 175.326 130.8 176.026 128.306C177.991 121.311 148.347 121.479 143.795 121.494C138.703 121.512 133.572 121.672 128.484 121.485C126.343 121.407 124.235 121.007 122.145 120.569C120.064 120.132 117.968 120.242 115.86 120.059C107.062 119.294 98.2451 119.405 89.4176 119.405C86.5477 119.41 83.6736 119.385 80.7995 119.393Z"
			fill="#DBDBDB"
		/>
		<path
			d="M151.422 89.6149L151.462 90.8648L149.205 91.6699L147.905 90.7055V89.2486L149.161 89.1803L149.199 88.8596L149.611 88.6009L151.422 89.6149Z"
			fill="#E1CFB5"
		/>
		<path
			d="M105.262 99.497L105.746 110.502L104.325 129.216L102.197 129.363L99.6128 112.367L96.0386 97.9842L105.262 99.497Z"
			fill="#1D2E36"
		/>
		<path
			d="M102.309 130.764H105.502L105.633 129.731L104.489 128.957L102.19 129.116L102.309 130.764Z"
			fill="#292949"
		/>
		<path
			d="M90.198 96.2237L89.7307 104.543L91.1159 129.279L93.2456 129.382L96.439 113.16L99.2539 95.367L90.198 96.2237Z"
			fill="#1D2E36"
		/>
		<path
			d="M93.1586 130.842L90.0497 130.971L89.7346 130.093L90.9422 129.023L93.2453 129.135L93.1586 130.842Z"
			fill="#292949"
		/>
		<path
			d="M102.583 71.0947L95.9362 70.3973C95.9362 70.3973 92.2394 70.7843 89.729 79.3766C88.3332 84.157 87.9081 91.6877 87.8066 97.4304C87.9631 98.2499 88.8746 101.836 92.7321 101.228C99.3538 100.183 102.879 99.1377 105.762 99.6592C108.219 100.183 108.01 96.8716 107.741 94.3469L106.762 86.5327L102.583 71.0947Z"
			fill="#92E7BF"
		/>
		<path
			d="M101.303 62.9972C99.6282 62.6351 97.9045 62.3122 96.2274 62.6371C95.8594 62.7075 95.4661 62.8337 95.2673 63.1441C95.1911 63.2642 95.1488 63.4028 95.1108 63.5394C94.6878 65.0791 94.1506 67.3596 95.1869 68.7792C95.3836 69.0503 95.6501 69.2635 95.9356 69.4394C96.3163 69.6732 96.7392 69.8305 97.1749 69.9153C97.0839 70.2982 96.9592 70.6727 96.8006 71.0328C97.1833 71.5978 98.9725 71.6661 99.3046 71.1239C99.3236 70.6541 99.3426 70.1844 99.3596 69.7167C99.5774 69.6298 99.7868 69.5263 99.9856 69.398C100.751 68.9055 101.254 68.1584 101.546 67.3203C101.866 66.4097 102.314 65.5426 102.604 64.6155C102.71 64.2782 102.794 63.8912 102.612 63.5849C102.418 63.26 102.001 63.1483 101.625 63.0676C101.515 63.0427 101.409 63.02 101.303 62.9972Z"
			fill="#D0D0D0"
		/>
		<path
			d="M102.032 67.2695C102.838 66.4603 103.779 65.3304 103.851 64.304C103.91 63.4555 103.792 62.2966 103.305 61.562C102.851 60.8749 101.687 60.4983 100.994 60.1009C100.342 59.7264 99.6889 59.3518 99.0375 58.9752C98.9022 58.8965 98.7626 58.8179 98.6082 58.791C98.4242 58.7599 98.2381 58.8055 98.0583 58.851C96.5166 59.24 94.9748 59.6312 93.4331 60.0202C93.137 60.0947 92.7986 60.2044 92.6908 60.4838C92.6252 60.6535 92.6633 60.8439 92.7035 61.0218C92.879 61.8041 93.2153 62.6049 93.2977 63.3996C93.4246 64.0266 93.4014 64.5875 93.8688 65.0448C94.3087 65.4753 94.8945 65.7112 95.2477 66.2389C95.5014 66.6196 95.6009 67.0791 95.7997 67.4888C96.7027 69.3513 100.357 68.8546 101.622 67.6668C101.749 67.5488 101.888 67.4143 102.032 67.2695Z"
			fill="#1D2E36"
		/>
		<path
			d="M101.177 71.6522L105.745 66.8221L104.668 53.7329L106.677 52.87L110.901 66.0419L109.408 70.0939L103.281 78.1502L101.177 71.6522Z"
			fill="#92E7BF"
		/>
		<path
			d="M106.246 53.0517C106.246 53.0517 106.246 51.8721 106.246 51.8535C106.246 51.4168 105.121 50.3945 104.835 50.0283C104.672 49.8192 103.924 50.9595 103.913 51.1478C103.898 51.4686 104.372 52.1577 104.501 52.493L104.101 53.0517L104.668 53.7305L106.246 53.0517Z"
			fill="#D0D0D0"
		/>
		<path
			d="M93.004 72.6557L87.8331 68.4485L87.1648 55.3324L85.0584 54.7323L82.6199 68.3285L84.6375 72.1569L91.7795 79.3669L93.004 72.6557Z"
			fill="#92E7BF"
		/>
		<path
			d="M85.5089 54.8599C85.5089 54.8599 85.3524 53.6907 85.3503 53.6721C85.2932 53.2375 86.2724 52.0827 86.5071 51.6833C86.6404 51.4557 87.535 52.4904 87.5688 52.6767C87.6259 52.9933 87.2495 53.7362 87.1628 54.0839L87.6344 54.5868L87.1628 55.3318L85.5089 54.8599Z"
			fill="#D0D0D0"
		/>
		<path
			d="M11.8174 64.4956C10.982 64.0962 10.1953 63.5974 9.47836 63.0159C9.24572 62.8276 8.99828 62.5606 9.07865 62.275C9.1717 61.9481 9.6116 61.8777 9.95844 61.9088C10.6098 61.9646 11.2485 62.1509 11.8259 62.453C11.9676 62.4096 11.7857 62.184 11.8491 62.0515C11.8787 61.9915 11.9549 61.9688 12.0226 61.9646C12.9573 61.915 13.3211 63.1587 13.6404 63.7588C12.9869 64.0941 12.3102 64.1086 11.7286 64.6176L11.8174 64.4956Z"
			fill="#D0D0D0"
		/>
		<path
			d="M51.9127 79.3105C51.9127 79.3105 53.1055 79.4823 53.1245 79.4843C53.5665 79.5485 55.4995 78.5945 55.9098 78.371C56.1446 78.2427 54.3638 77.3652 54.1756 77.328C53.852 77.2659 53.0864 77.626 52.7311 77.7026L52.2236 77.2349L51.4559 77.6839L51.9127 79.3105Z"
			fill="#D0D0D0"
		/>
		<path
			d="M22.2357 128.125L22.0687 129.406L24.5198 131.018L25.9981 129.901L24.8561 128.454L22.4049 126.842L22.2357 128.125Z"
			fill="#1D2E36"
		/>
		<path
			d="M22.147 127.694L24.0377 111.344L21.7642 93.0375L22.2738 89.1449L32.438 88.7662L32.8038 95.1049L31.0971 111.369L24.6806 128.53L22.147 127.694Z"
			fill="#1D2E36"
		/>
		<path
			d="M34.0604 71.0308L38.6264 73.6569L42.2513 78.6256L51.9036 77.2556L52.206 79.859L41.2425 83.4329L35.6233 81.3759L34.0604 71.0308Z"
			fill="#92E7BF"
		/>
		<path
			d="M39.9005 90.599L39.9279 107.099L36.5336 128.339L34.0909 128.316L32.7479 108.673L29.6052 89.0117L39.9005 90.599Z"
			fill="#1D2E36"
		/>
		<path
			d="M34.0955 129.325L36.7243 130.644L38.0587 129.363L36.7496 128.059L34.1081 128.032L34.0955 129.325Z"
			fill="#1D2E36"
		/>
		<path
			d="M20.7132 96.2114C20.7364 95.4953 20.7766 94.8993 20.783 94.6862C20.8358 92.7678 21.0093 90.8536 21.2546 88.9497C21.4745 87.2424 21.7516 83.0063 22.0498 81.3094C22.2972 79.9083 23.1559 76.4131 23.7755 75.1135C25.3194 71.8831 24.8414 71.5851 31.6196 70.6249C34.591 70.2048 35.2932 72.0155 37.7211 74.0994C37.96 74.3043 37.9473 75.5274 38.0023 75.8523C38.0996 76.4234 38.2054 76.9925 38.3259 77.5595C38.4211 78.0045 38.5247 78.4494 38.641 78.8902C38.641 78.8902 40.6798 87.9688 42.0291 89.8975C43.3784 91.8262 46.1637 94.5206 41.5025 96.1679C40.6777 96.4286 39.8465 96.677 38.9857 96.8115C38.9857 96.8115 35.1747 96.3976 33.2164 96.4121C31.4124 96.4245 29.5999 96.6045 27.8255 96.9025C26.6391 97.0971 25.4273 97.3785 24.3677 97.9207C23.3547 98.4381 22.625 99.1996 21.5972 99.7356C21.4999 99.7873 21.3942 99.8391 21.2821 99.8246C20.692 99.7459 20.6645 97.7179 20.7132 96.2114Z"
			fill="#92E7BF"
		/>
		<path
			d="M31.0235 71.4639C30.7464 71.7205 29.9914 72.0082 29.0905 71.9419C28.9086 71.9295 28.7521 71.8074 28.6992 71.6377C28.5554 71.1576 28.4454 70.6672 28.3672 70.1726C27.6989 70.1457 26.9037 70.0443 26.6034 69.4586C26.5082 69.2744 26.4807 69.0654 26.4553 68.8606C26.3454 68.0142 26.2354 67.1678 26.1233 66.3214C26.0852 66.0296 26.0472 65.7274 26.1275 65.4418C26.2671 64.9431 26.7366 64.6017 27.2082 64.3678C28.4116 63.7697 29.8814 63.6228 31.1229 64.1402C31.4528 64.2788 31.7721 64.4671 31.9879 64.7486C32.3199 65.1832 32.3559 65.7605 32.377 66.3027C32.3982 66.8035 32.4193 67.3023 32.4404 67.8031C32.4637 68.3784 32.4489 69.0447 31.9879 69.4027C31.7425 69.5931 31.4042 69.6594 31.2011 69.8932C30.8458 70.303 31.1081 70.9321 31.0235 71.4639Z"
			fill="#D0D0D0"
		/>
		<path
			d="M35.8438 61.2442C35.9051 61.0062 35.9791 60.7723 36.0722 60.5447C36.2752 60.0542 36.5713 59.5948 36.696 59.0795C36.8208 58.5643 36.7278 57.9455 36.2858 57.6371C36.0003 57.4385 35.6302 57.4074 35.2812 57.4116C34.1307 57.424 32.9844 57.7468 32.001 58.3325C31.0641 58.8912 30.288 59.5824 29.1459 59.5162C28.0737 59.4541 27.4265 59.8059 26.5319 60.4578C25.5633 61.1635 24.1337 61.6912 23.3173 62.5231C22.9768 62.8707 22.759 63.3177 22.6236 63.7813C22.1584 65.3851 22.7336 67.231 24.0322 68.3092C24.324 68.5513 24.6455 68.7562 24.9754 68.9445C25.6585 69.3336 26.4833 69.822 27.251 69.8116C27.0734 69.1887 25.8869 67.4152 26.2126 66.7634C26.7286 65.7286 28.5559 65.4865 29.571 65.3975C30.6792 65.3003 31.8339 65.7431 32.9125 65.5858C33.6887 65.4741 34.5262 64.8326 35.038 64.2676C35.6788 63.5744 35.6175 62.1133 35.8438 61.2442Z"
			fill="#1B2E35"
		/>
		<path
			d="M25.6977 72.0497L20.364 73.3886L13.9432 63.6209L11.2341 64.3369L15.7515 78.3987L26.1482 79.6238L25.6977 72.0497Z"
			fill="#92E7BF"
		/>
		<path
			d="M155.468 126.236C155.703 125.983 155.976 125.844 156.284 125.892C156.58 125.938 156.849 126.134 156.97 126.397C157.052 126.577 157.071 126.776 157.088 126.972C157.173 127.986 157.166 130.393 155.785 130.739C155.206 130.883 154.726 130.482 154.571 129.971C154.339 129.205 154.683 127.08 155.468 126.236Z"
			fill="#1D2E36"
		/>
		<path
			d="M154.979 110.948L155.051 117.13C154.966 119.876 155.404 122.636 155.321 125.383C155.302 126.014 155.133 126.68 155.296 127.305C155.455 127.918 155.964 128.025 156.484 127.715C157.185 127.299 157.07 126.33 157.187 125.623C157.506 123.677 157.882 121.742 158.217 119.801C158.379 118.849 158.043 117.515 158.117 116.55L159.139 111.37L154.979 110.948Z"
			fill="#D0D0D0"
		/>
		<path
			d="M162.193 100.959L159.444 112.91L158.858 124.197L154.499 124.102L153.313 92.5305L161.766 92.6815L162.193 100.959Z"
			fill="#1D2E36"
		/>
		<path
			d="M149.233 126.011C149.478 125.771 149.755 125.649 150.062 125.715C150.356 125.778 150.614 125.991 150.724 126.262C150.8 126.446 150.808 126.647 150.817 126.845C150.857 127.866 150.745 130.272 149.355 130.539C148.772 130.651 148.311 130.221 148.18 129.701C147.979 128.919 148.414 126.812 149.233 126.011Z"
			fill="#1D2E36"
		/>
		<path
			d="M151.531 111.815L151.161 117.987C151.131 119.702 150.896 121.403 150.714 123.106C150.545 124.689 150.808 126.337 149.964 127.777C149.699 128.228 149.162 128.528 148.788 127.98C148.5 127.558 148.672 126.852 148.614 126.374C148.388 124.416 148.107 122.467 147.868 120.511C147.749 119.553 148.151 118.235 148.124 117.268L147.354 112.047L151.531 111.815Z"
			fill="#D0D0D0"
		/>
		<path
			d="M146.013 100.232L145.658 111.833L147.519 123.565L151.954 123.695L151.757 110.997L154.105 100.468L154.892 93.5766L147.855 92.7633L146.013 100.232Z"
			fill="#1D2E36"
		/>
		<path
			d="M151.577 69.0198L159.159 68.8418L163.554 76.8423L161.991 84.3357L161.714 93.3005L150.898 93.4454L147.341 92.8804L148.287 83.2989L147.743 70.5015L151.577 69.0198Z"
			fill="#92E7BF"
		/>
		<path
			d="M152.573 62.0979C154.066 58.9855 158.294 60.6576 158.876 63.6293C159.256 65.5683 157.505 67.8489 155.458 68.1779C155.441 68.2503 155.223 68.8815 155.215 68.9415C155.181 69.2437 155.109 69.5354 155.031 69.8314C155.001 69.941 153.297 69.432 153.108 69.374C153.043 69.3533 152.969 69.3285 152.937 69.2685C152.878 69.1547 153.231 68.2876 153.259 68.1717C153.288 68.033 153.32 67.8944 153.35 67.7557C152.421 67.1846 151.911 66.1209 151.85 65.0862C151.789 64.0473 152.125 63.0333 152.573 62.0979Z"
			fill="#D0D0D0"
		/>
		<path
			d="M155.735 59.4773C155.943 59.7339 156 60.0671 156.034 60.3858C156.053 60.5762 156.067 60.7707 156.006 60.9507C155.763 61.6668 155.023 61.5509 154.422 61.7268C153.68 61.9441 153.009 62.4159 152.544 62.9933C151.814 63.8955 151.958 65.1724 152.091 66.2671C152.138 66.652 152.256 67.2315 151.92 67.5357C151.58 67.844 150.884 67.7178 150.541 67.5046C150.226 67.3101 149.987 66.9831 149.63 66.8859C149.251 66.7824 148.712 66.8921 148.57 66.5279C148.53 66.4265 148.538 66.3085 148.486 66.2133C148.306 65.8925 147.737 66.1864 147.46 65.9443C147.288 65.7953 147.32 65.5201 147.407 65.309C147.494 65.0979 147.623 64.8868 147.604 64.6612C147.587 64.4667 147.464 64.3012 147.396 64.119C147.263 63.759 147.371 63.3182 147.659 63.0554C148.194 62.567 149.101 62.7677 149.666 62.3145C149.96 62.0786 150.103 61.7102 150.243 61.3605C150.38 61.0107 150.541 60.6445 150.852 60.4313C151.235 60.1664 151.734 60.272 152.119 59.9512C152.345 59.7629 152.51 59.5042 152.758 59.3449C153.083 59.1358 153.692 59.0117 154.077 58.9289C154.693 58.7985 155.342 58.991 155.735 59.4773Z"
			fill="#1D2E36"
		/>
		<path
			d="M157.929 64.9252C157.832 65.991 157.165 67.0836 157.563 68.0749C157.965 69.0724 159.206 69.3766 160.274 69.5401C160.45 69.567 162.696 69.9974 162.711 69.956C162.962 69.2338 163.22 68.495 163.688 67.8866C164.155 67.2782 164.876 66.8084 165.64 66.8415C165.426 66.7815 165.344 66.5083 165.392 66.289C165.441 66.0717 165.58 65.8834 165.671 65.6785C165.896 65.1736 165.8 64.5486 165.439 64.1409C164.718 63.3256 163.231 63.4746 162.637 62.564C162.269 61.9991 162.402 61.2478 162.247 60.5898C161.951 59.315 160.604 58.9115 159.638 58.2492C158.616 57.5477 157.631 57.1193 156.355 57.1918C154.776 57.2807 153.449 58.7066 153.889 60.2255C154.452 62.1729 157.54 62.3364 157.908 64.292C157.946 64.4989 157.948 64.7121 157.929 64.9252Z"
			fill="#1D2E36"
		/>
		<path
			d="M156.918 68.9781L154.567 72.2292L153.461 72.1423L151.619 68.8416L156.918 68.9781Z"
			fill="#D0D0D0"
		/>
		<path
			d="M151.622 68.8395L147.347 69.0672C147.347 69.0672 142.549 71.3767 142.231 71.2028C141.914 71.029 136.729 60.8722 136.729 60.8722L134.189 61.6793L137.363 73.2992L140.432 76.4033L149.109 74.9899L151.622 68.8395Z"
			fill="#92E7BF"
		/>
		<path
			d="M134.583 61.5386C134.583 61.5386 133.912 60.5597 133.902 60.5432C133.652 60.181 134.005 58.7179 134.035 58.2585C134.052 57.9957 135.323 58.5338 135.437 58.6869C135.634 58.9456 135.632 59.7754 135.714 60.1252L136.364 60.3714L136.279 61.2426L134.583 61.5386Z"
			fill="#D0D0D0"
		/>
		<path
			d="M44.7919 31.4534L31.4343 25.5473V9.82578L44.7919 15.7319V31.4534Z"
			fill="#5AE4A7"
		/>
		<path
			d="M44.7933 31.4567L58.153 25.5505V9.82901L44.7933 15.7352V31.4567Z"
			fill="#4BC28F"
		/>
		<path
			d="M31.4343 9.82551L44.7919 15.7317L58.1516 9.82551L44.7919 4.46154L31.4343 9.82551Z"
			fill="#B8F1D4"
		/>
		<path
			d="M151.472 22.6797L140.247 17.7151V4.50588L151.472 9.46839V22.6797Z"
			fill="#5AE4A7"
		/>
		<path
			d="M151.47 22.6797L162.694 17.7151V4.50588L151.47 9.46839V22.6797Z"
			fill="#4BC28F"
		/>
		<path
			d="M140.247 4.50516L151.472 9.46766L162.696 4.50516L151.472 0L140.247 4.50516Z"
			fill="#B8F1D4"
		/>
		<path
			d="M83.559 35.2192L81.9855 34.5218L82.2626 33.9216L83.8361 34.619L83.559 35.2192ZM80.7589 33.9775L78.919 33.1622L79.196 32.562L81.036 33.3774L80.7589 33.9775ZM77.6945 32.62L75.8545 31.8046L76.1316 31.2045L77.9715 32.0198L77.6945 32.62ZM74.6279 31.2603L72.7879 30.445L73.065 29.8449L74.9049 30.6602L74.6279 31.2603ZM71.5634 29.9028L69.7235 29.0874L70.0005 28.4873L71.8405 29.3027L71.5634 29.9028ZM68.499 28.5452L66.659 27.7299L66.9361 27.1298L68.776 27.9451L68.499 28.5452ZM65.4345 27.1856L63.5945 26.3703L63.8716 25.7701L65.7115 26.5855L65.4345 27.1856ZM62.3679 25.8281L60.528 25.0127L60.805 24.4126L62.645 25.2279L62.3679 25.8281ZM59.3035 24.4705L57.4635 23.6552L57.7406 23.055L59.5805 23.8704L59.3035 24.4705ZM56.239 23.1109L54.3991 22.2956L54.6761 21.6954L56.516 22.5108L56.239 23.1109ZM53.1745 21.7534L51.3346 20.938L51.6116 20.3379L53.4516 21.1532L53.1745 21.7534Z"
			fill="#182F35"
		/>
		<path
			d="M17.7281 35.2716L17.3284 34.7419L18.1595 34.1396L18.5592 34.6694L17.7281 35.2716ZM19.6399 33.8851L19.2402 33.3553L20.8623 32.1799L21.262 32.7097L19.6399 33.8851ZM22.3427 31.9254L21.943 31.3956L23.5651 30.2201L23.9649 30.7499L22.3427 31.9254ZM25.0477 29.9635L24.648 29.4338L26.2701 28.2583L26.6698 28.7881L25.0477 29.9635ZM27.7505 28.0038L27.3508 27.474L28.9729 26.2986L29.3726 26.8283L27.7505 28.0038ZM30.4533 26.044L30.0536 25.5142L31.6757 24.3388L32.0754 24.8686L30.4533 26.044ZM33.1561 24.0822L32.7564 23.5524L34.3785 22.377L34.7782 22.9068L33.1561 24.0822ZM35.8589 22.1224L35.4592 21.5927L37.0813 20.4172L37.4811 20.947L35.8589 22.1224Z"
			fill="#182F35"
		/>
		<path
			d="M13.0104 54.6406L1.78674 49.6761V36.4669L13.0104 41.4294V54.6406Z"
			fill="#5AE4A7"
		/>
		<path
			d="M13.0113 54.6399L24.2371 49.6753V36.4661L13.0113 41.4286V54.6399Z"
			fill="#4BC28F"
		/>
		<path
			d="M1.78674 36.4661L13.0104 41.4286L24.2362 36.4661L13.0104 31.961L1.78674 36.4661Z"
			fill="#B8F1D4"
		/>
		<path
			d="M83.0281 38.3012L82.7807 37.6887L82.8378 37.6659L83.0852 38.2784L83.0281 38.3012ZM84.3351 37.7921L84.0877 37.1796L85.9636 36.4511L86.211 37.0637L84.3351 37.7921ZM87.4609 36.5774L87.2135 35.9648L89.0894 35.2364L89.3368 35.8489L87.4609 36.5774ZM90.5888 35.3605L90.3414 34.748L92.2173 34.0195L92.4647 34.6321L90.5888 35.3605ZM93.7146 34.1458L93.4672 33.5332L95.3431 32.8048L95.5905 33.4173L93.7146 34.1458ZM96.8425 32.9289L96.5951 32.3164L98.471 31.588L98.7184 32.2005L96.8425 32.9289ZM99.9704 31.7142L99.723 31.1016L101.599 30.3732L101.846 30.9857L99.9704 31.7142ZM103.096 30.4974L102.849 29.8848L104.725 29.1564L104.972 29.7689L103.096 30.4974ZM106.224 29.2826L105.977 28.6701L107.853 27.9416L108.1 28.5542L106.224 29.2826ZM109.35 28.0658L109.102 27.4532L110.978 26.7248L111.226 27.3373L109.35 28.0658ZM112.478 26.851L112.23 26.2385L114.106 25.51L114.354 26.1226L112.478 26.851ZM115.604 25.6363L115.356 25.0237L117.232 24.2953L117.48 24.9078L115.604 25.6363ZM118.732 24.4194L118.484 23.8069L120.36 23.0784L120.607 23.691L118.732 24.4194ZM121.857 23.2047L121.61 22.5921L123.486 21.8637L123.733 22.4762L121.857 23.2047ZM124.985 21.9878L124.738 21.3753L126.614 20.6469L126.861 21.2594L124.985 21.9878ZM128.111 20.7731L127.864 20.1605L129.739 19.4321L129.987 20.0447L128.111 20.7731ZM131.239 19.5563L130.991 18.9437L132.867 18.2153L133.115 18.8278L131.239 19.5563ZM134.365 18.3415L134.117 17.729L135.993 17.0005L136.241 17.6131L134.365 18.3415ZM137.493 17.1267L137.245 16.5142L139.121 15.7858L139.369 16.3983L137.493 17.1267ZM140.618 15.9099L140.371 15.2974L142.247 14.5689L142.494 15.1815L140.618 15.9099ZM143.746 14.6952L143.499 14.0826L145.375 13.3542L145.622 13.9667L143.746 14.6952Z"
			fill="#182F35"
		/>
		<path
			d="M94.2823 56.418L71.524 46.3564V19.5737L94.2823 29.6353V56.418Z"
			fill="#5AE4A7"
		/>
		<path
			d="M94.2802 56.418L117.038 46.3564V19.5737L94.2802 29.6353V56.418Z"
			fill="#4BC28F"
		/>
		<path
			d="M71.524 19.5746L94.2823 29.6362L117.04 19.5746L94.2823 10.4381L71.524 19.5746Z"
			fill="#B8F1D4"
		/>
		<path
			d="M131.93 49.8668L130.088 49.0597L130.363 48.4595L132.205 49.2666L131.93 49.8668ZM128.86 48.5216L127.018 47.7146L127.292 47.1144L129.135 47.9215L128.86 48.5216ZM125.789 47.1765L123.947 46.3694L124.222 45.7693L126.064 46.5764L125.789 47.1765ZM122.718 45.8314L120.876 45.0243L121.151 44.4241L122.993 45.2312L122.718 45.8314ZM119.647 44.4862L117.805 43.6792L118.08 43.079L119.922 43.8861L119.647 44.4862ZM116.576 43.1411L114.734 42.334L115.009 41.7339L116.851 42.541L116.576 43.1411ZM113.506 41.7939L111.664 40.9868L111.938 40.3867L113.781 41.1938L113.506 41.7939ZM110.435 40.4488L108.593 39.6417L108.868 39.0416L110.71 39.8486L110.435 40.4488ZM107.364 39.1036L105.522 38.2966L105.797 37.6964L107.639 38.5035L107.364 39.1036Z"
			fill="#182F35"
		/>
		<path
			d="M64.4493 50.0945L64.0834 49.542L65.7774 48.4679L66.1433 49.0205L64.4493 50.0945ZM67.2726 48.3044L66.9067 47.7519L68.6008 46.6779L68.9666 47.2304L67.2726 48.3044ZM70.096 46.5123L69.7301 45.9598L71.4241 44.8857L71.79 45.4383L70.096 46.5123ZM72.9193 44.7222L72.5535 44.1697L74.2475 43.0957L74.6134 43.6482L72.9193 44.7222ZM75.7427 42.9301L75.3768 42.3776L77.0708 41.3035L77.4367 41.8561L75.7427 42.9301ZM78.5661 41.138L78.2002 40.5854L79.8942 39.5114L80.2601 40.0639L78.5661 41.138ZM81.3873 39.3479L81.0214 38.7954L82.7155 37.7213L83.0813 38.2739L81.3873 39.3479Z"
			fill="#182F35"
		/>
		<path
			d="M56.6731 78.3609L41.1076 71.48V53.1614L56.6731 60.0443V78.3609Z"
			fill="#5AE4A7"
		/>
		<path
			d="M56.6704 78.3609L72.2338 71.48V53.1614L56.6704 60.0443V78.3609Z"
			fill="#4BC28F"
		/>
		<path
			d="M41.1076 53.1615L56.6731 60.0444L72.2365 53.1615L56.6731 46.9138L41.1076 53.1615Z"
			fill="#B8F1D4"
		/>
		<path
			d="M140.629 62.5089L125.065 55.628V37.3114L140.629 44.1923V62.5089Z"
			fill="#5AE4A7"
		/>
		<path
			d="M140.629 62.5089L156.192 55.628V37.3114L140.629 44.1923V62.5089Z"
			fill="#4BC28F"
		/>
		<path
			d="M125.065 37.3108L140.629 44.1917L156.192 37.3108L140.629 31.0632L125.065 37.3108Z"
			fill="#B8F1D4"
		/>
		<path
			d="M154.478 90.4066C154.017 90.864 153.382 91.2592 152.733 91.1785C152.473 91.1454 152.215 91.1019 151.959 91.044C151.758 90.9985 151.553 90.9447 151.392 90.8143C151.231 90.6839 151.13 90.4625 151.197 90.2638C151.293 89.9865 151.637 89.912 151.889 89.761C152.204 89.5747 152.397 89.2374 152.644 88.9663C153.207 88.3475 153.968 88.2896 154.687 87.9585C154.721 87.944 155.11 88.3827 155.144 88.422C155.404 88.7076 155.427 88.7697 155.281 89.1318C155.099 89.5809 154.835 90.0507 154.478 90.4066Z"
			fill="#D0D0D0"
		/>
		<path
			d="M159.408 69.3905L165.563 78.5622L164.636 83.6716L155.105 90.1324L153.311 88.599L159.683 80.7165L159.436 75.7353L159.408 69.3905Z"
			fill="#5BE4A8"
		/>
	</svg>
</template>
