import Distributor from "@/services/distributor.service";
import throttle from "lodash.throttle";

export default {
  namespaced: true,
  state: {
    currentDistributor: null,
    currentDistributorPartners: null,
    currentDistributorCompanies: null,
  },
  mutations: {
    currentDistributor(state, payload) {
      state.currentDistributor = payload;
    },
    currentDistributorPartners(state, payload) {
      state.currentDistributorPartners = payload;
    },
    currentDistributorCompanies(state, payload) {
      state.currentDistributorCompanies = payload;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("currentDistributor", null);
      commit("currentDistributorPartners", null);
      commit("currentDistributorCompanies", null);
    },
    getCurrent: throttle(
      ({ commit }) => {
        return Distributor.getCurrentDistributor().then((res) => {
          commit("currentDistributor", res.data);
          return res.data;
        });
      },
      500,
      { trailing: false }
    ),
    getCurrentDistriburPartners: throttle(
      ({ commit }) => {
        return Distributor.getCurrentDistributorPartners().then((res) => {
          commit("currentDistributorPartners", res.data);
        });
      },
      500,
      { trailing: false }
    ),
    removePartnerFromDistributor({ commit }, partner_id) {
      return Distributor.removePartnerFromDistributor(partner_id).then(() => {
        Distributor.getCurrentDistributorPartners().then((res) => {
          commit("currentDistributorPartners", res.data);
        });
      });
    },
    takePaymentOwnership({ commit }, partner_id) {
      return Distributor.takePaymentOwnership(partner_id).then(() => {
        Distributor.getCurrentDistributorPartners().then((res) => {
          commit("currentDistributorPartners", res.data);
        });
      });
    },
    getDistributorCompanies: throttle(({ commit }) => {
      return Distributor.getCurrentDistributorCompanies().then((res) => {
        commit("currentDistributorCompanies", res.data);
      });
    }),
    setCurrent(_, body) {
      return Distributor.setCurrentDistributor(body);
    },
    updateCurrentPartnerAsDistributor(_, body) {
      return Distributor.updateCurrentPartnerAsDistributor(body).then(
        (res) => res.data
      );
    },
  },
};
