<template>
	<div v-if="!loading" class="mt-16 sm:mt-24 md:mt-44 flex w-3/4">
		<div
			v-for="{ id, name } in titles"
			:key="id"
			class="flex flex-col items-center w-full"
		>
			<p class="text-xsm leading-6 uppercase">{{ $t("login-into") }}</p>
			<p class="font-bold text-md">{{ $t(`${name.toLocaleLowerCase()}`) }}</p>
			<div
				class="bg-primary w-64 h-32 flex justify-center items-center my-10 rounded-lg"
			>
				<div
					v-if="id === 0 && distributors && distributors.length > 0"
					class="flex justify-center"
				>
					<!-- <img src="../../assets/Choose1.png" alt="Choose 1 img" /> -->
					<DistributorsSVG id="choose-1" />
				</div>
				<div
					v-if="id === 1 && partners && partners.length > 0"
					class="flex justify-center"
				>
					<!-- <img src="../../assets/Choose2.png" alt="Choose 2 img" /> -->
					<PartnersSVG id="choose-2" />
				</div>
				<div
					v-if="id === 2 && companies && companies.length > 0"
					class="flex justify-center"
				>
					<!-- <img src="../../assets/Choose3.png" alt="Choose 3 img" /> -->
					<CompaniesSVG id="choose-3" />
				</div>
			</div>
			<div class="max-h-52 overflow-y-scroll names">
				<BasicButton
					v-for="cdp in checkNames(id)"
					href="#"
					:key="cdp.name"
					:disabled="loading"
					@click.prevent="logIntoAccount(id, cdp)"
					><span class="choose underline underline-offset-2">{{
						cdp.name
					}}</span></BasicButton
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import BasicButton from "../../components/basic/BasicButton";
import DistributorsSVG from "@/svg/pages/ChoosePageDistributorsSvg";
import CompaniesSVG from "@/svg/pages/ChoosePageCompaniesSvg.vue";
import PartnersSVG from "@/svg/pages/ChoosePagePartnersSvg.vue";

export default {
	i18nOptions: { namespaces: "choose-page" },
	components: {
		BasicButton,
		DistributorsSVG,
		PartnersSVG,
		CompaniesSVG,
	},
	data() {
		return {
			loading: true,
			titles: [],
		};
	},
	watch: {
		companies() {
			if (
				this.companies?.length === 1 &&
				!this.partners?.length &&
				!this.distributors?.length
			) {
				// automatically log into the only company - first one
				console.log("auto log into company via route change");
				this.logIntoAccount(2, this.companies[0]);
			}
		},
	},
	async mounted() {
		await this.$store.dispatch("company/resetState");
		await this.$store.commit("distributor/currentDistributor", null);
		await this.$store.commit("partner/currentPartner", null);
		localStorage.setItem("name", null);
		await this.$store.commit("profile/name", null);
		await this.$store.dispatch("profile/setRole", null);
		await this.$store.dispatch("profile/getChoice", null);
		this.$store
			.dispatch("profile/getChoice")
			.then((choices) => {
				if (choices["distributors"]?.length) {
					this.titles.push({ id: 0, name: "Distributors" });
				}
				if (choices["partners"].length) {
					this.titles.push({ id: 1, name: "Partners" });
				}
				if (choices["companies"].length) {
					this.titles.push({ id: 2, name: "Companies" });
				}

				if (
					choices["companies"].length === 0 &&
					choices["distributors"].length === 0 &&
					choices["partners"].length === 0
				) {
					const newPartner = localStorage.getItem("new_partner");

					if (newPartner) {
						localStorage.removeItem("name");
						localStorage.removeItem("loggedIn");
						this.$router.push("/partner-details");
						return;
					} else {
						localStorage.removeItem("loggedIn");
						this.$router.push("/company-details");
						return;
					}
				}
			})
			.catch(() => {
				this.$router.push("/");
			});

		if (
			this.companies.length === 1 &&
			!this.partners?.length &&
			!this.distributors?.length
		) {
			// automatically log into the only company - first one
			console.log("auto log into company via mounted");
			this.logIntoAccount(2, this.companies[0]);
		} else {
			this.loading = false;
		}

		document.title = this.$t("title");
	},
	computed: {
		...mapState({
			companies: (state) => state.profile.companies,
			distributors: (state) => state.profile.distributors,
			partners: (state) => state.profile.partners,
		}),
	},
	methods: {
		checkNames(index) {
			if (index === 1) return this.partners;
			if (index === 2) return this.companies;
			return this.distributors;
		},
		async logIntoAccount(index, cdp) {
			this.$store.dispatch("navbar/setOptions", []);
			let type;
			if (index === 0) {
				type = "distributor";
				await this.$store.dispatch("distributor/setCurrent", {
					distributor_id: cdp.id,
				});
				await this.$store.commit("distributor/currentDistributor", cdp);
			}
			if (index === 1) {
				type = "partner";
				await this.$store.dispatch("partner/setCurrent", {
					partner_id: cdp.id,
				});
				await this.$store.commit("partner/currentPartner", cdp);
			}
			if (index === 2) {
				type = "company";
				await this.$store.dispatch("company/setCurrent", {
					company_id: cdp.id,
				});
				await this.$store.dispatch("company/getCurrent");
			}

			await this.$store.commit("profile/name", cdp.name);
			await this.$store.dispatch("profile/setRole", type);
			localStorage.setItem("name", cdp.name);
			await this.$router.push({ name: "dashboard-company" });
		},
	},
};
</script>
<style scoped>
/* svg {
	width: 50%;
	height: 50%;
} */
.choose {
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
}

.names::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.names {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
</style>
