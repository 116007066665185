<template>
  <span
    :class="setErrorClass"
    v-if="errors.has(field) || errors.hasNotice(field)"
  >
    <p class="text-red italic" v-for="error in errors.get(field)" :key="error">
      <small>{{ error }}</small>
    </p>
    <p
      class="help-block__notice text-red"
      v-for="notice in errors.getNotice(field)"
      :key="notice"
    >
      <BasicNotice :value="notice" />
    </p>
  </span>
</template>

<script>
import BasicNotice from "./BasicNotice";

export default {
  components: {
    BasicNotice,
  },
  computed: {
    setErrorClass() {
      return this.errorClass ? ` ${this.errorClass}` : " bottom-1";
    },
  },
  props: ["errors", "field", "errorClass"],
};
</script>
