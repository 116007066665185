import TransactionsRules from "../services/transactionsRules.service";

export default {
  namespaced: true,
  state: {
    curentCompanyTransactionRules: null,
    transactionRuleTypes: null,
    transactionRulesCurrentCompany: null,
    currentDoc: null,
  },
  mutations: {
    curentCompanyTransactionRules(state, payload) {
      state.curentCompanyTransactionRules = payload;
    },
    transactionRuleTypes(state, payload) {
      state.transactionRuleTypes = payload;
    },
    transactionRulesCurrentCompany(state, payload) {
      state.transactionRulesCurrentCompany = payload;
    },
    setCurrentDoc(state, payload) {
      state.currentDoc = payload;
    },
  },
  actions: {
    curentCompanyTransactionRules({ commit }) {
      return TransactionsRules.curentCompanyTransactionRules().then((res) => {
        commit("curentCompanyTransactionRules", res.data);
        return res.data;
      });
    },
    transactionRuleTypes({ commit }) {
      TransactionsRules.transactionRuleTypes().then((res) => {
        commit("transactionRuleTypes", res.data);
        return res.data;
      });
    },
    transactionRulesCurrentCompany({ commit }) {
      TransactionsRules.transactionRulesCurrentCompany().then((res) => {
        commit("transactionRulesCurrentCompany", res.data);
        return res.data;
      });
    },
    createTransactionRule({ dispatch }, body) {
      return TransactionsRules.createTransactionRule(body).then(() => {
        dispatch("curentCompanyTransactionRules");
      });
    },
    getCurrentDoc({ commit }, id) {
      return TransactionsRules.getCurrentDoc(id).then((res) => {
        commit("setCurrentDoc", res.data);
      });
    },
    editCurrentRule(_, body) {
      return TransactionsRules.editCurrentRule(body).then((res) => res);
    },
    deleteCurrentRule(_, body) {
      return TransactionsRules.deleteCurrentRule(body).then((res) => res);
    },
  },
};
