export default {
  namespaced: true,
  state: {
    current: 0,
    options: null,
  },
  mutations: {
    current(state, payload) {
      state.current = payload;
    },
    options(state, payload) {
      state.options = payload;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("current", 0);
    },
    setCurrent({ commit }, id) {
      commit("current", id);
    },
    setOptions({ commit }, options) {
      commit("options", options);
    },
  },
};
