import http from "./http.service";

class Dimensions {
  getCurrentCompanyDimensions() {
    return http.get("/api/dimensions").then((res) => {
      return res.data;
    });
  }
  createDimension(body) {
    return http.post("/api/dimensions", body).then((res) => {
      return res.data;
    });
  }
  updateDimension({ id, ...data }) {
    return http.post(`/api/dimensions/${id}`, data);
  }
  deleteDimension({ id, ...data }) {
    return http.delete(`/api/dimensions/${id}`, data);
  }
}

export default new Dimensions();
