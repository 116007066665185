import http from "./http.service";

class Distributor {
  setCurrentDistributor(body) {
    return http.post("/api/user/current-distributor", body).then((res) => res.data);
  }

  getCurrentDistributor() {
    return http.get("/api/user/current-distributor").then((res) => res.data);
  }

  getCurrentDistributorPartners() {
    return http.get("/api/distributor/partners").then((res) => res.data);
  }

  getCurrentDistributorCompanies() {
    return http.get("/api/companies?distributor_companies=true").then((res) => {
      res.data.data.forEach((el) => {
        el.company_status = el.active ? "active" : "non-activ";
      });
      return res.data;
    });
  }

  updateCurrentPartnerAsDistributor({ id, ...rest }) {
    console.log(rest);
    return http
      .post(`/api/distributor/update-partner/${id}`, {
        ...rest,
      })
      .then((res) => res.data);
  }

  removePartnerFromDistributor(partner_id) {
    return http
      .delete(`/api/distributor/remove-partner/${partner_id}`)
      .then((res) => res.data);
  }

  takePaymentOwnership(partner_id) {
    return http
      .post(`/api/distributor/take-payment-ownership/${partner_id}`)
      .then((res) => res.data);
  }
}

export default new Distributor();
