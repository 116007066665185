import Partner from "@/services/partner.service";

export default {
  namespaced: true,
  state: {
    currentPartner: null,
    currentPartnerCompanies: null,
  },
  mutations: {
    currentPartner(state, payload) {
      state.currentPartner = payload;
    },
    currentPartnerCompanies(state, payload) {
      state.currentPartnerCompanies = payload;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("currentPartner", null);
      commit("currentPartnerCompanies", null);
    },
    getCurrent({ commit }) {
      Partner.getCurrentPartner().then((res) => {
        commit("currentPartner", res.data);
      });
    },
    setCurrent(_, body) {
      return Partner.setCurrentPartner(body);
    },
    createPartnerFromDistributor(_, body) {
      return Partner.createPartnerFromDistributor(body);
    },
    getCompaniesFromCurrentPartner({ commit }) {
      return Partner.getCompaniesFromCurrentPartner().then((res) => {
        commit("currentPartnerCompanies", res.data);
      });
    },
    removeCompanyFromPartner({ commit }, companyId) {
      return Partner.removeCompanyFromPartner(companyId).then(() => {
        Partner.getCompaniesFromCurrentPartner().then((res) => {
          commit("currentPartnerCompanies", res.data);
        });
      });
    },
    takePaymentOwnership({ commit }, companyId) {
      return Partner.takePaymentOwnership(companyId).then(() => {
        Partner.getCompaniesFromCurrentPartner().then((res) => {
          commit("currentPartnerCompanies", res.data);
        });
      });
    },
    returnPaymentOwnership({ commit }, companyId) {
      return Partner.returnPaymentOwnership(companyId).then(() => {
        Partner.getCompaniesFromCurrentPartner().then((res) => {
          commit("currentPartnerCompanies", res.data);
        });
      });
    },

    toggleCompanyStatus({ commit }, companyId) {
      return Partner.toggleCompanyStatus(companyId).then(() => {
        Partner.getCompaniesFromCurrentPartner().then((res) => {
          commit("currentPartnerCompanies", res.data);
        });
      });
    },

    updateCompanyOwner(_, body) {
      return Partner.updateCompanyOwner(body);
    },
  },
};
