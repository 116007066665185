<template>
  <div class="my-2">
    <div class="flex items-center">
      <input
        type="checkbox"
        :checked="checked"
        :value="value"
        :style="{
          border: disabled
            ? '1px solid rgba(40, 49, 67, 0.04)'
            : '1px solid rgba(40, 49, 67, 0.2)',
        }"
        :id="inputId"
        :disabled="disabled"
        class="cursor-pointer"
      />
      <label
        :for="inputId"
        class="text-xsm font-semibold text-darkText ml-3"
        :class="labelClass"
      >
        <a
          v-if="url"
          :href="url"
          target="_blank"
          class="underline underline-offset-2"
        >
          {{ label }}
        </a>
        <span v-else>{{ label }}</span>
        <slot></slot>
      </label>
    </div>
    <BasicError :errors="errors" :field="field" v-if="errors" />
  </div>
</template>

<script>
import BasicError from "./BasicError";

export default {
  components: {
    BasicError,
  },
  props: [
    "errors",
    "label",
    "value",
    "field",
    "id",
    "disabled",
    "checked",
    "optional",
    "labelClass",
    "url",
  ],
  computed: {
    inputId() {
      return this.id || this.field;
    },
  },
};
</script>

<style scoped>
/* input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px;
  border: 1px solid rgba(40, 49, 67, 0.2) !important;
  accent-color: rgb(255, 255, 255);
}
input[type="checkbox"]:checked {
  border: 1px solid rgba(40, 49, 67, 0.2) !important;
} */

/* styling checkbox */
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px;

  background-color: #fff;
  margin: 0;
  font: inherit;
  color: yellow;
  width: 1.15em;
  height: 1.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em rgba(40, 49, 67, 0.2);
  background-color: green;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"][disabled]:checked::before {
  background-color: rgba(0, 128, 0, 0.267);
}
label {
  font-family: "Inter", sans-serif;
}
</style>
