import i18next from "i18next";
import I18NextVue from "i18next-vue";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const detectionConfig = { caches: ["localStorage", "cookie"] };

export const i18nextPromise = i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    detection: detectionConfig,
    debug: false,
    fallbackLng: "en",
    ns: [
      "translation",
      "404",
      "bank-statements",
      "my-profile",
      "choose-page",
      "company_accountant-dashboard",
      "company_bank-setup",
      "company_dashboard",
      "company_dimensions",
      "company_erp",
      "company_help",
      "company_labels",
      "company_main-data",
      "company_rules-documents",
      "company_rules-transactions",
      "company_setup-nav",
      "company-add-document",
      "company-details",
      "distributor",
      "email-confirmation",
      "export",
      "forgot-password",
      "inbox",
      "join-distributor",
      "join-partner",
      "landing-page",
      "login-page",
      "main-navbar",
      "my-profile",
      "partner",
      "signup",
      "user",
      "wizard",
      "filters",
    ],
  });

export default function (app) {
  app.use(I18NextVue, { i18next });
  return app;
}
