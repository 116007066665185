import { encodeCountry } from "@/utils/helper";
import http from "./http.service";

class Profile {
  get() {
    return http.get("/api/user").then((res) => res.data);
  }

  updateProfile(body) {
    body["language"] = encodeCountry(body["language"]);
    return http.post("api/user", body);
  }

  createPartner(partner) {
    return http.post("api/user/create-partner", partner);
  }

  updatePassword(body) {
    return http.post("api/user/change-password", body);
  }

  setPreferredLanguage(data) {
    return http.post("api/preferred-language", data).then((res) => res.data);
  }

  getChoice() {
    return http.get("/api/user/choice-account").then((res) => res.data);
  }

  chooseAccount(body) {
    return http.post("/api/account", body).then((res) => res.data);
  }

  invite(id, hash) {
    return http.post(`api/partner/join/${hash}`, { company_id: id,invitation_hash:hash });
  }

  getNordigenBanks() {
    return http.post("api/integration/nordigen/banks").then((res) => res.data);
  }

  connectBank(body) {
    return http.post("api/integration/nordigen", body).then((res) => res.data);
  }

  reconnectBank(body) {
    return http.post("api/integration/nordigen", body).then((res) => res.data);
  }

  connectBankCompleted(body) {
    return http
      .post("api/integration/nordigen/completed", body)
      .then((res) => res.data);
  }

  connectEconomic(body) {
    return http.post("api/integration/economic", body);
  }

  sendEmail(body) {
    return http.post("api/send/email", body);
  }
}

export default new Profile();
