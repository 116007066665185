// NAV VARIABLES

export const companyNav = [
  {
    name: "dashboard",
    redirect: "/c/dashboard/table",
  },
  {
    name: "bank-statement",
    redirect: "/c/bank-statement",
    code: "bank_statement",
  },
  {
    name: "inbox",
    redirect: "/c/inbox",
    code: "inbox",
  },
  {
    name: "export",
    redirect: "/c/export",
    code: "export",
  },
  {
    name: "company-setup",
    redirect: "/c/company/setup",
    code: "company_setup",
  },
  {
    name: "users",
    redirect: "/c/users",
    code: "users",
  },
  {
    name: "help",
    redirect: "/c/help",
  },
];

export const distributorNav = [
  {
    name: "partners",
    redirect: "/d/partners",
  },
  {
    name: "companies",
    redirect: "/d/companies",
  },
  {
    name: "logout",
    redirect: "/",
  },
];

export const partnerNav = [
  {
    name: "companies",
    redirect: "/companies",
  },
  {
    name: "logout",
    redirect: "/",
  },
];

// LANGUAGE VARIABLES

export const languages = {
  English: "en",
  en: "en",
  Danish: "da",
  da: "da",
};
export const codes = {
  en: "English",
  da: "Danish",
};

// Nordigen INTEGRATION VARIABLES
export const nordigen = {
  client_redirect_uri_wizard: `${
    process.env.VUE_APP_APP === "/"
      ? "https://orprio.atomdev.dev"
      : process.env.VUE_APP_APP
  }/wizard?id=1`,

  client_redirect_uri_company: `${
    process.env.VUE_APP_APP === "/"
      ? "https://orprio.atomdev.dev"
      : process.env.VUE_APP_APP
  }/c/company/setup/bank`,

  baseUrl: "https://api-sandbox.aiia.eu",

  client_id: "orpio-6aa1a980-c143-4495-9e38-f360a972a06e",
};

export const economic = {
  baseUrl:
    "https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=4hr8h3LYA8bg8BxapPVQtTYMvZIcKJPGMDuNvTpwyE81",
};

export const url_redirect_company = `${
  nordigen.baseUrl ? nordigen.baseUrl : "/"
}/v1/oauth/connect?client_id=${
  nordigen.client_id
}&scope=accounts%20offline_access%20payments:inbound%20payments:outbound&redirect_uri=${
  nordigen.client_redirect_uri_company
}&response_type=code`;

//RFC 2822 standard email validation
/* eslint-disable */
export const RFC2822EMAILREGEX =
  /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
/* eslint-enable */
