<template>
  <div
    class="pt-16 min-h-fit w-10/12 flex flex-col md:flex-row md:w-3/4 xl:w-7/12 2xl:w-1/2"
  >
    <div
      class="flex justify-center items-center md:w-1/2 bg-primary rounded-l-lg"
    >
      <img src="../../assets/Confirmation.png" alt="Join Page img" />
    </div>
    <div
      class="flex flex-col justify-center items-center md:w-1/2 rounded-r-lg px-7 py-14 md:px-14 bg-white"
    >
      <p class="text-lg text-darkText font-bold mb-6">
        {{ $t("email-verified")
        }}<!-- Email verified -->
      </p>
      <p class="text-xbase text-darkText mb-6 text-center">
        {{ $t("fill-partner-info") }}
        <!-- Please fill in your company information to proceed with signup -->
      </p>
      <form class="w-full">
        <div class="mb-6">
          <BasicInput
            :label="$t('partner-name') /* Company name */"
            :fullWidth="true"
            field="name"
            :errors="errors"
            :placeholder="$t('partner-name') /* Company name */"
            labelClass="text-sm"
            :value="partnerName"
            @input="partnerName = $event"
          />
        </div>

        <BasicButton
          :disabled="loading"
          color="primary"
          :fullWidth="true"
          type="submit"
          class="mt-4"
          @click.prevent="createPartner"
          @keyup.enter="createPartner"
        >
          <p class="font-bold text-base uppercase">
            {{ $t("start-trial")
            }}<!-- START YOUR FREE TRIAL -->
          </p>
        </BasicButton>
      </form>
      <p class="mt-8 text-sm text-center italic">
        {{ $t("trial-14")
        }}<!--  Your 14 day trial will start today and you will have access to all
          features. -->
      </p>
      <p class="mt-4 text-sm text-center italic">
        {{ $t("trial-reminder") }}
        <!--  We will send you a reminder on the 12th day with a payment link which
          you can use to start your paid subscription, if you wish. -->
      </p>
    </div>
  </div>
</template>

<script>
import BasicInput from "../../components/basic/BasicInput";
import BasicButton from "../../components/basic/BasicButton";
import Errors from "../../utils/errors";

export default {
  i18nOptions: { namespaces: "company-details" },
  name: "landing-page",
  data() {
    return {
      partnerName: "",
      errors: new Errors(),
      loading: false,
      invitation_hash: localStorage.getItem("hash"),
    };
  },
  components: {
    BasicInput,
    BasicButton,
  },
  methods: {
    async createPartner() {
      try {
        await this.$store.dispatch("profile/createPartner", {
          name: this.partnerName,
          invitation_hash: this.invitation_hash,
        });
        localStorage.setItem("loggedIn", true);
        localStorage.removeItem("newPartner");
        this.$router.push({ name: "choose" });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
