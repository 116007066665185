<template>
  <div
    :class="[
      {
        'w-full': fullWidth,
      },
      allClass,
    ]"
  >
    <label
      v-if="labelText"
      class="inline-block mb-1 text-darkText font-semibold text-sm"
      :for="field"
      :class="labelClasses"
      >{{ $t(labelText) }} <span v-if="requiredField" class="text-red">*</span
      ><i v-if="optional">{{ $t(" - Optional") }}</i></label
    >
    <div :class="[inputClasses]">
      <Multiselect
        v-model="inpVal"
        :preselect-first="true"
        :close-on-select="true"
        :clear-on-select="true"
        :preserve-search="true"
        :searchable="options && options.length > 0"
        :id="field"
        class="multiselect-custom !rounded-md"
        :class="{
          'border-red': field && errors?.errors && errors.errors[field],
        }"
        :options="options"
        :disabled="disabled"
        :group-values="groupValues"
        :group-label="groupLabel"
        :placeholder="placeholder"
        :label="label"
        :valueProp="valueProp"
        :value="{ value: inpVal }"
        :track-by="trackBy"
        :allow-empty="allowEmpty"
        :group-select="false"
        :multiple="multiple"
        @clear="handleInput"
        @select.self="$emit('input', $event)"
        :style="{ 'min-height': height + 'px' }"
        :autocomplete="autocomplete"
        :canClear="canClear"
        :noOptionsText="$t('empty')"
      >
      </Multiselect>
      <BasicError v-if="errors" :errors="errors" :field="field" :errorClass="errorClass" />
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import BasicError from "./BasicError";

export default {
  i18nOptions: { namespaces: "company_labels" },
  data() {
    return {
      inpVal: this.value,
    };
  },
  props: {
    errors: {},
    errorClass: {},
    valueProp: {},
    label: {},
    labelText: {},
    groupLabel: {},
    groupValues: {},
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    canClear:{
      type:Boolean,
      default:true
    },
    field: {},
    options: {},
    placeholder: {},
    inputClasses: {},
    labelClasses: { default: "" },
    allowEmpty: { default: false },
    multiple: { default: true },
    trackBy: { default: "name" },
    searchParam: { default: "" },
    optional: { default: false },
    fullWidth: { default: false },
    allClass: {},
    height: { default: 40 },
    requiredField: {
      default: false,
    },
    autocomplete: {}
  },
  components: {
    Multiselect,
    BasicError,
  },
  methods: {
    handleInput() {
      this.$emit("input", null);
    },    
  },
  watch:{
    value(newVal){
      this.inpVal = newVal
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
