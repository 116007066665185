import http from "./http.service";
import app from "../main";
import i18next from "i18next";

export default {
  getCookie() {
    return http.get("/sanctum/csrf-cookie");
  },
  async checkEmail(email) {
    await this.getCookie();
    return http
      .post("api/check-email?language=" + i18next.language, { email: email })
      .then((res) => res.data);
  },
  async register(user, hash) {
    await this.getCookie();
    if (hash) user["prefilled_user"] = hash;
    return http.post("/register", user);
  },

  async login(user) {
    await this.getCookie();
    try {
      return await http.post("/login?language=" + i18next.language, user);
    } catch (err) {
      return err;
    }
  },

  async logout() {
    await this.getCookie();
    await http.post("/logout");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("orprio_role");
    localStorage.removeItem("name");
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("new_partner");
    app.$store.dispatch("profile/resetState");
    app.$store.dispatch("auth/resetState");
    app.$store.dispatch("company/resetState");
    app.$store.dispatch("distributor/resetState");
    app.$store.dispatch("partner/resetState");
    return;
  },
  forceLogout() {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("orprio_role");
    localStorage.removeItem("name");
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("new_partner");
    app.$store.dispatch("profile/resetState");
    app.$store.dispatch("auth/resetState");
    app.$store.dispatch("company/resetState");
    app.$store.dispatch("distributor/resetState");
    app.$store.dispatch("partner/resetState");
    app.$router.push("/");
  },

  async updateUserLanguage() {
    await this.getCookie();
    try {
      return await http.post("/api/user/change-language/" + i18next.language);
    } catch (err) {
      return err;
    }
  },

  isAuthenticated() {
    this.getCookie();
    const loggedIn = localStorage.getItem("loggedIn");
    const verifiedEmail =
      app.$router.currentRoute.value.name === "company-details";
    const verifiedWizard = app.$router.currentRoute.value.name === "wizard";
    if (loggedIn || verifiedEmail || verifiedWizard) {
      http
        .get("/api/user")
        .then((res) => {
          if (res.status === 200) {
            const profile = res.data.data;
            app.$store.commit("profile/profile", profile);
            app.$store.dispatch("auth/authSuccess", {
              isAuthenticated: true,
              redirect:
                app.$router.currentRoute.value.name === "login-tab"
                  ? "company-details"
                  : null,
            });
            app.$store.dispatch("profile/getChoice");
          }
        })
        .catch((err) => {
          console.log("auth service", err.response.data.message);
          if (
            err.response.data.message === "Your email address is not verified."
          ) {
            console.log("errrrr response");
            app.$store.dispatch(
              "auth/email-verification",
              localStorage.getItem("email")
            );
            http.post("/logout");
            return app.$router.push("/confirm");
          }
          app.$store.dispatch("auth/resetState");
        });
    }
    return;
  },
};
