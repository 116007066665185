import Dimensions from "@/services/dimensions.service";
const emptyDimension = {
  name: "",
};
export default {
  namespaced: true,
  state: {
    dimensions: null,
    newDimension: { ...emptyDimension },
    singleDimension: null,
  },
  mutations: {
    dimensions(state, payload) {
      state.dimensions = payload;
    },
    newDimension(state, payload) {
      state.newDimension = payload;
    },
    singleDimension(state, payload) {
      state.singleDimension = payload;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("newDimension", { ...emptyDimension });
      commit("dimensions", null);
      commit("singleDimension", null);
    },
    chooseDimension({ commit }, data) {
      commit("singleDimension", data);
    },
    getCurrentCompanyDimensions({ commit }) {
      return Dimensions.getCurrentCompanyDimensions().then((res) => {
        commit("dimensions", res.data);
      });
    },
    createDimension({ state }) {
      return Dimensions.createDimension(state.newDimension);
    },
    updateDimension({ state }) {
      return Dimensions.updateDimension(state.singleDimension);
    },
    deleteDimension({ state }) {
      return Dimensions.deleteDimension(state.singleDimension);
    },
  },
};
