import Labels from "@/services/labels.service";
const emptyLabel = {
  name: "",
  additional_info: "",
  dimension: { id: "" },
  account: "",
  vat: "",
};
export default {
  namespaced: true,
  state: {
    labels: null,
    newLabel: { ...emptyLabel },
    singleLabel: null,
  },
  mutations: {
    labels(state, payload) {
      state.labels = payload;
    },
    newLabel(state, payload) {
      state.newLabel = payload;
    },
    singleLabel(state, payload) {
      state.singleLabel = payload;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("newLabel", { ...emptyLabel });
      commit("labels", null);
      commit("singleLabel", null);
    },
    chooseLabel({ commit }, data) {
      commit("singleLabel", data);
    },
    getLabelsForCurrentCompany({ commit }) {
      return Labels.getLabelsForCurrentCompany().then((res) => {
        const result = res.data.data.map((label) => {
          if (label.dimension && label.dimension.id) {
            return label;
          } else {
            return { ...label, dimension: { id: "", name: "" } };
          }
        });
        commit("labels", result);
      });
    },
    createNewLabel({ state }) {
      return Labels.createNewLabel(state.newLabel);
    },
    updateLabel({ state }) {
      return Labels.updateLabel(state.singleLabel);
    },
    deleteLabel({ state }) {
      return Labels.deleteLabel(state.singleLabel);
    },
  },
};
