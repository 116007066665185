<template>
  <span v-html="nl2Br"></span>
</template>

<script>
export default {
  i18nOptions: { namespaces: "api_validation" },
  props: ["value"],
  computed: {
    nl2Br() {
      return this.$t(this.value).split("\n").join("<br>");
    },
  },
};
</script>
