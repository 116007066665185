<template>
  <div
    class="flex flex-col items-start"
    :class="[{ 'w-full': fullWidth === true }, allClass]"
  >
    <label
      v-if="label"
      class="inline-block mb-1 text-darkText font-semibold text-sm"
      :class="labelClass"
      :for="id"
      >{{ label }} <span class="text-red" v-if="requiredField">*</span
      ><i v-if="optional">{{ $t(" - Optional") }}</i></label
    >
    <div class="relative" :class="{ 'w-full': fullWidth === true }">
      <input
        :id="id"
        ref="input"
        v-bind="$attrs"
        class="px-3 py-2 leading-normal block w-full text-darkText bg-white rounded-md text-left appearance-none outline-none border border-lightText"
        :class="[
          {
            'border-red': showErr,
            'w-full': fullWidth === true,
            'search-input': searchIcon,
          },
          inputClass,
          height,
        ]"
        :type="typeName"
        :value="value"
        :placeholder="$t(setPlaceholder)"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
        @keydown="$emit('keydown', $event)"
        @blur="$emit('blur', $event)"
        @keyup="$emit('keyup', $event)"
        @change="$emit('change', $event.target.value)"
      />
      <div
        v-if="id.includes('password') && !showPass"
        @click.prevent="setShow(true)"
        class="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
      >
        <!-- <span data-icon="fa:eye" class="iconify"></span> -->
      </div>
      <div
        v-if="id.includes('password') && showPass"
        @click.prevent="setShow(false)"
        class="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
      >
        <span data-icon="fa:eye-slash" class="iconify"></span>
      </div>
      <div v-if="searchIcon" class="absolute right-4 top-1/2 -translate-y-1/2">
        <SearchSVG />
      </div>
      <div
        v-if="
          errors &&
          errors.has(field) &&
          !searchIcon &&
          !id.includes('password') &&
          type !== 'date'
        "
        class="absolute right-4 top-1/2 -translate-y-1/2"
      >
        <ErrorSVG />
      </div>
    </div>
    <BasicError
      v-if="errors"
      :errors="errors"
      :field="field"
      :errorClass="errorClass"
    />
  </div>
</template>

<script>
import SearchSVG from "../../svg/SearchSVG";
import ErrorSVG from "../../svg/ErrorSVG";
import BasicError from "./BasicError";
export default {
  i18nOptions: { namespaces: "company_labels" },
  name: "TextInput",

  inheritAttrs: false,
  components: {
    SearchSVG,
    BasicError,
    ErrorSVG,
  },
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${12}`;
      },
    },
    type: {
      type: String,
      default: "text",
    },
    field: {
      type: String,
    },
    requiredField: {
      type: Boolean,
      default: false,
    },
    value: String,
    placeholder: {
      type: String,
    },
    label: String,
    errors: {
      required: false,
    },
    errorClass: {
      type: String,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    allClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "h-10",
    },
     
  },
  data() {
    return {
      showPass: false,
      showErr: false,
    };
  },
  computed: {
    typeName() {
      if (this.id.includes("password")) {
        return this.showPass === true ? "text" : "password";
      }
      return this.type;
    },
    setPlaceholder() {
      if (!this.placeholder) return this.label ? this.label : this.$t("search");
      return this.placeholder;
    },
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
    setShow(show) {
      this.showPass = show;
    },
  },
  watch: {
    errors: {
      handler(errs) {
        if (errs && errs.has(this.field)) {
          this.showErr = true;
        } else {
          this.showErr = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]::-webkit-datetime-edit-text {
  display: inline-block;
  max-height: 2px !important;
  max-width: 2px !important;
  background: gray;
  color: transparent;
  transform: translateY(15px);
  margin-left: 2px;
  margin-right: 2px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url(@/svg/calendar.svg);
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input:disabled {
  background: #f3f4f6;
}

.search-input {
  height: 32px;
  max-width: 196px;
}
</style>
