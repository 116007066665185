import DocumentsRulesService from "@/services/documentsRules.service";

export default {
  namespaced: true,
  state: {
    docRulesForCurCompany: null,
    docRulesTypes: null,
    currentDoc: null,
  },
  mutations: {
    setDRulesForCurCompany(state, payload) {
      state.docRulesForCurCompany = payload;
    },
    setDocRulesTypes(state, payload) {
      state.docRulesTypes = payload;
    },
    setCurrentDoc(state, payload) {
      state.currentDoc = payload;
    },
  },
  actions: {
    getDRulesForCurCompany({ commit }) {
      return DocumentsRulesService.getDocumentsRulesForCurCompany().then(
        (res) => {
          commit("setDRulesForCurCompany", res.data);
          return res.data;
        }
      );
    },
    getDocRulesTypes({ commit }) {
      DocumentsRulesService.getDocRuleTypes().then((res) => {
        commit("setDocRulesTypes", res.data);
      });
    },
    createNewDocRule(_, body) {
      return DocumentsRulesService.createNewDocRule(body);
    },
    getCurrentDoc({ commit }, id) {
      return DocumentsRulesService.getCurrentDoc(id).then((res) => {
        commit("setCurrentDoc", res.data);
      });
    },
    editCurrentRule(_, body) {
      return DocumentsRulesService.editCurrentRule(body).then(
        (res) => res.data
      );
    },
    deleteCurrentRule(_, body) {
      return DocumentsRulesService.deleteCurrentRule(body).then(
        (res) => res.data
      );
    },
  },
};
