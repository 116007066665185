<template>
  <Suspense>
    <template #default>
      <App />
    </template>
    <template #fallback>
      <div>
        <h1></h1>
      </div>
    </template>
  </Suspense>
</template>

<script>
import App from "./App.vue";

export default {
  name: "TheSuspenser",
  components: {
    App,
  },
};
</script>
