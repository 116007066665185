<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="5.075" stroke="#283143" stroke-width="1.85" />
    <line
      x1="10.3081"
      y1="10"
      x2="13"
      y2="12.6919"
      stroke="#283143"
      stroke-width="1.85"
      stroke-linecap="round"
    />
  </svg>
</template>
