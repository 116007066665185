import http from "./http.service";

class Email {
  confirm(params) {
    console.log(params);
    return http.get(`${params}`).then((res) => res.data);
  }

  resendConfirmation() {
    return http.post("email/resend_confirmation").then((res) => res.data);
  }

  emailVerification(email) {
    return http
      .post(`api/email-verification`, { email: email })
      .then((res) => res.data);
  }
}

export default new Email();
