<template>
	<div
		class="pt-16 min-h-fit w-10/12 flex flex-col md:flex-row md:w-3/4 xl:w-7/12 2xl:w-1/2"
	>
		<div
			class="flex justify-center items-center md:w-1/2 bg-primary rounded-l-lg"
		>
			<!-- <img src="../../assets/Confirmation.png" alt="Join Page img" /> -->
			<CompanyDetailsPageSvg />
		</div>
		<div
			class="flex flex-col justify-center items-center md:w-1/2 rounded-r-lg px-7 py-14 md:px-14 bg-white"
		>
			<p class="text-lg text-darkText font-bold mb-6">
				{{ $t("email-verified")
				}}<!-- Email verified -->
			</p>
			<p class="text-xbase text-darkText mb-6 text-center">
				{{ $t("fill-company-info") }}
				<!-- Please fill in your company information to proceed with signup -->
			</p>
			<form class="w-full">
				<div class="mb-6">
					<BasicInput
						:label="$t('company-name') /* Company name */"
						:fullWidth="true"
						field="name"
						:errors="errors"
						:placeholder="$t('company-name') /* Company name */"
						labelClass="text-sm"
						:value="company.name"
						@input="updateCompany({ name: $event })"
					/>
				</div>
				<div class="mb-6">
					<BasicInput
						:label="$t('company-vat') /* Company number (VAT) */"
						field="vat"
						:errors="errors"
						:fullWidth="true"
						:placeholder="$t('company-vat') /* Company number (VAT) */"
						labelClass="text-sm"
						:value="company.vat"
						@input="updateCompany({ vat: $event })"
					/>
				</div>
				<BasicButton
					:disabled="loading"
					color="primary"
					:fullWidth="true"
					type="submit"
					class="mt-4"
					@click.prevent="createCompany"
					@keyup.enter="createCompany"
				>
					<p class="font-bold text-base uppercase">
						{{ $t("start-trial")
						}}<!-- START YOUR FREE TRIAL -->
					</p>
				</BasicButton>
			</form>
			<p class="mt-8 text-sm text-center italic">
				{{ $t("trial-14")
				}}<!--  Your 14 day trial will start today and you will have access to all
        features. -->
			</p>
			<p class="mt-4 text-sm text-center italic">
				{{ $t("trial-reminder") }}
				<!--  We will send you a reminder on the 12th day with a payment link which
        you can use to start your paid subscription, if you wish. -->
			</p>
		</div>
	</div>
</template>

<script>
import BasicInput from "../../components/basic/BasicInput";
import BasicButton from "../../components/basic/BasicButton";
import Errors from "../../utils/errors";
import CompanyDetailsPageSvg from "@/svg/pages/CompanyDetailsPageSvg.vue";

let emptyCompany = {
	name: "",
	vat: "",
};
export default {
	i18nOptions: { namespaces: "company-details" },
	name: "landing-page",
	data() {
		return {
			ok: false,
			company: { ...emptyCompany },
			errors: new Errors(),
			loading: false,
		};
	},
	components: {
		CompanyDetailsPageSvg,
		BasicInput,
		BasicButton,
	},
	methods: {
		createCompany() {
			this.loading = true;
			this.$store
				.dispatch("company/createCompany", this.company)
				.then((res) => {
					if (localStorage.getItem("hash")) {
						this.$store.dispatch("profile/join", [
							res.data.data.id,
							localStorage.getItem("hash"),
						]);
					}
					this.ok = true;
					this.$router.push("/wizard?id=0");
				})
				.catch((err) => {
					this.loading = false;
					this.errors.set(err.response.data.errors);
				});
		},
		updateCompany(payload) {
			const payloadKey = Object.keys(payload);
			this.company[payloadKey] = payload[payloadKey];
		},
	},
	beforeUnmount() {
		if (!this.ok) {
			this.$store.dispatch("auth/logout");
		}
	},
};
</script>
