import Inbox from "@/services/inbox.service";
export default {
  namespaced: true,
  state: {
    openDocuments: null,
    openDocumentT: null,
    waitingPayments: null,
    documentToEdit: null,
    openEditModal: false,
    openAssignModal: false,
    documentToAssign: false,
    openAssignToTransactionModal: false,
    transactionToAssign: null,
  },
  mutations: {
    openEditModal(state, payload) {
      state.openEditModal = payload;
    },
    openAssignToTransactionModal(state, payload) {
      state.openAssignToTransactionModal = payload;
    },
    transactionToAssign(state, payload) {
      state.transactionToAssign = payload;
    },
    openAssignModal(state, payload) {
      state.openAssignModal = payload;
    },
    documentToAssign(state, payload) {
      state.documentToAssign = payload;
    },
    openDocuments(state, payload) {
      state.openDocuments = payload;
    },
    openDocumentT(state, payload) {
      state.openDocumentT = payload;
    },
    waitingPayments(state, payload) {
      state.waitingPayments = payload;
    },
    documentToEdit(state, payload) {
      state.documentToEdit = payload;
    },
    resetState(state) {
      state.openDocuments = null;
      state.openDocumentT = null;
      state.waitingPayments = null;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("resetState");
    },

    getAllOpenDocuments({ commit }) {
      return Inbox.getAllOpenDocuments().then((res) => {
        commit("openDocuments", res.data.data);
      });
    },

    getAllOpenDocumentTransactions({ commit }) {
      return Inbox.getAllOpenDocumentTransactions().then((res) => {
        commit("openDocumentT", res.data.data);
      });
    },

    getWaitingPayments({ commit }) {
      return Inbox.getWaitingPayments().then((res) => {
        commit("waitingPayments", res.data.data);
      });
    },

    matchTransactionWithDocument(_, body) {
      return Inbox.matchTransactionWithDocument(body);
    },

    unmatchTrasactionWithDocument(_, body) {
      return Inbox.unmatchTrasactionWithDocument(body);
    },

    markWithReady(_, matchId) {
      return Inbox.markWithReady(matchId);
    },

    switchToWaiting(_, body) {
      return setTimeout(() => Inbox.switchToWaiting(body), 200);
    },

    chooseDocumentToEdit({ commit }, body) {
      commit("documentToEdit", body);
    },
  },
};
